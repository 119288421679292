import { createContext, FC, PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react'
import { isArray, isNumber, isString } from 'remeda'
import useSuspenseSwr, { immutableOptions } from '../../../hooks/useSuspenseSwr'
import {
  FormInputType,
  LeadSessionEventType,
  PublishedSelectionOption,
  PublishedSlide,
  SlideType,
} from '../../../orval/loov'
import {
  ComplexPublishedSlide,
  createLead,
  createLeadSession,
  createLeadSessionEvent,
  FormValue,
  getLead,
  LeadDetail,
  mergeLead,
  PublishedFormSlide,
  PublishedScenarioDetail,
  PublishedSelectionSlide,
  setLeadProfileToLead,
} from '../../../orval/loovPublic'
import { ApiError } from '../../commons/errors'
import { LocalStorageKey } from '../../commons/localStorageKey'
import SwrKey from '../../commons/swrKey'
import { assertNever, ensure } from '../../commons/utils'

type NextTarget = {
  nextUrl: string | null
  nextPublishedSlideId: string | null
}

export type SelectionAnswerHistoryItem = {
  publishedSlideId: string
  publishedSelectionSlideContentText: string
  publishedSelectionOptionValue: string
}

type SlideIdOrUrl = { slideId: string } | { url: string }

export type ScenarioPlayerContextValue = {
  publishedScenario: PublishedScenarioDetail
  currentSlide?: ComplexPublishedSlide
  replayEventTarget: EventTarget
  currentTime: number
  playbackRate: number
  volume: number
  setCurrentTime: (currentTime: number) => void
  setPlaybackRate: (playbackRate: number) => void
  setVolume: (volume: number) => void
  goNext: (target: NextTarget) => void
  goBack: () => void
  goBackToBeginning: () => void
  goBackToSelectionSlide: (selectionAnswerHistoryIndex: number) => void
  recordViewingTime: () => void
  onViewStart: () => Promise<unknown>
  onAnswerSelection: (
    publishedSlide: PublishedSelectionSlide,
    publishedSelectionOption: PublishedSelectionOption,
  ) => Promise<unknown>
  onAnswerForm: (publishedSlide: PublishedFormSlide, data: Record<string, string | string[]>) => Promise<unknown>
  selectionAnswerHistory: SelectionAnswerHistoryItem[]
}

export const ScenarioPlayerContext = createContext<ScenarioPlayerContextValue | undefined>(undefined)

export type ScenarioPlayerProviderProps = {
  publishedScenario: PublishedScenarioDetail
  needsNotify?: boolean
  overrideLeadId?: string
  leadProfileId?: string
  preview?: boolean
  excludedQueryKeys?: readonly string[]
  onViewStart?: (publishedSlide: PublishedSlide) => void
}

const ScenarioPlayerProvider: FC<PropsWithChildren<ScenarioPlayerProviderProps>> = ({
  children,
  publishedScenario,
  needsNotify,
  overrideLeadId,
  leadProfileId,
  preview,
  excludedQueryKeys,
  onViewStart: parentOnViewStart,
}) => {
  const slideMap: Map<string, ComplexPublishedSlide> = useMemo(() => {
    return new Map(publishedScenario.publishedSlides.map((slide) => [slide.id, slide]))
  }, [publishedScenario])
  const {
    data: { lead, leadSession },
  } = useSuspenseSwr(
    [SwrKey.LEAD_AND_SESSION, { publishedScenarioId: publishedScenario.id }],
    () =>
      getLeadAndSession({
        publishedScenarioId: publishedScenario.id,
        preview,
        overrideLeadId,
        leadProfileId,
        needsNotify,
      }),
    immutableOptions,
  )
  const answeredFormSlideIdsSetRef = useRef(lead ? new Set(lead.answeredFormSlideIds) : new Set())
  const replayEventTargetRef = useRef(new EventTarget())
  const currentTimeRef = useRef(0)
  const playbackRateRef = useRef(1)
  const volumeRef = useRef(1)
  const viewingTimeRef = useRef<number>()
  const isSlideViewingRef = useRef(false)
  // history は answeredFormSlideIdsRef より後ろに定義すること
  const [history, setHistory] = useState(getInitialHistory)

  // 設問回答履歴機能のために、回答済み情報を保持する
  const [selectionAnswerHistory, setSelectionAnswerHistory] = useState<SelectionAnswerHistoryItem[]>([])

  const currentSlideId = history.at(-1)
  const currentSlide = currentSlideId ? slideMap.get(currentSlideId) : undefined

  const setCurrentTime = useCallback((currentTime: number) => {
    currentTimeRef.current = currentTime
    // 再生時間の最大値を、視聴時間として保持する
    if (viewingTimeRef.current === undefined || viewingTimeRef.current < currentTime) {
      viewingTimeRef.current = currentTime
    }
  }, [])
  const setPlaybackRate = useCallback((playbackRate: number) => (playbackRateRef.current = playbackRate), [])
  const setVolume = useCallback((volume: number) => (volumeRef.current = volume), [])

  function externalUrl(nextUrlString: string) {
    const currentUrlParams = new URLSearchParams(window.location.search)
    // 除外対象のクエリパラメータを削除
    excludedQueryKeys?.forEach((key) => currentUrlParams.delete(key))

    const nextUrl = new URL(nextUrlString)
    const nextUrlParams = nextUrl.searchParams

    const newParams = new URLSearchParams(nextUrlParams)
    // パラメータのマージ（キーが重複した場合、上書きしない）
    currentUrlParams.forEach((value, key) => newParams.set(key, value))

    nextUrl.search = newParams.toString()
    return nextUrl.toString()
  }

  function resolveNextSlideIdOrUrl({ nextUrl, nextPublishedSlideId }: NextTarget): SlideIdOrUrl | null {
    const nextSlide = nextPublishedSlideId ? slideMap.get(nextPublishedSlideId) : undefined
    if (nextUrl) {
      // 遷移先にURLが指定されている場合
      return { url: externalUrl(nextUrl) }
    } else if (nextSlide) {
      // 遷移先にスライドが指定されている場合
      if (nextSlide.type === SlideType.FORM && answeredFormSlideIdsSetRef.current.has(nextSlide.slideId)) {
        // 次のスライドが回答済みのフォームスライドの場合はスキップする
        return resolveNextSlideIdOrUrl({ ...nextSlide.publishedFormSlideContent })
      }

      return { slideId: nextSlide.id }
    } else {
      return null
    }
  }

  function getInitialHistory() {
    // 先頭のスライドが存在した場合、そのスライドから次に表示すべきスライドを解決する
    const initialSlide = publishedScenario.publishedSlides[0]
    if (initialSlide) {
      const next = resolveNextSlideIdOrUrl({ nextPublishedSlideId: initialSlide.id, nextUrl: null })
      if (next && 'slideId' in next) {
        return [next.slideId]
      }
    }

    return []
  }

  function goNext(nextTarget: NextTarget) {
    onViewEnd({ recordViewingTime: false })

    const next = resolveNextSlideIdOrUrl(nextTarget)

    if (!next) {
      return
    }

    if ('slideId' in next) {
      setHistory([...history, next.slideId])
    } else if ('url' in next) {
      // 遷移先にURLが指定されている場合は、URLを開く
      // 動画スライドは同一タブ内で開く
      // 動画スライドの再生完了時はクリックによりトリガーされた open ではないため、ポップアップブロックされることがあるため
      const target = ensure(currentSlide).type === SlideType.VIDEO ? '_self' : '_blank'
      window.open(next.url, target, 'noreferrer')
    } else {
      assertNever(next)
    }
  }

  function goBack() {
    // スキップ対象ではないスライドが見つかるまで順に遡る
    // OPTIMIZE: goNextのロジックと共通化したい
    for (let step = 2; step <= history.length; step++) {
      const targetSlideId = ensure(history.at(-step))
      const targetSlide = ensure(slideMap.get(targetSlideId))
      if (targetSlide.type === SlideType.SELECTION) {
        // 設問回答履歴がある場合は削除
        const selectionAnswerHistoryIndex = selectionAnswerHistory.findLastIndex(
          (item) => item.publishedSlideId === targetSlideId,
        )
        if (selectionAnswerHistoryIndex !== -1) {
          setSelectionAnswerHistory(selectionAnswerHistory.slice(0, selectionAnswerHistoryIndex))
        }
      }

      // 回答済みフォームスライドの場合はスキップ
      if (targetSlide.type === SlideType.FORM && answeredFormSlideIdsSetRef.current.has(targetSlide.slideId)) {
        continue
      }

      // スキップ対象でないスライドであれば、遷移する
      onViewEnd()
      return setHistory(history.slice(0, 1 - step))
    }

    // 戻るスライドがない場合
    if (currentSlide?.type === SlideType.VIDEO) {
      // 動画スライドの場合、リプレイをトリガーする
      replayEventTargetRef.current.dispatchEvent(new Event('replay'))
    }
  }

  function goBackToSelectionSlide(selectionAnswerHistoryIndex: number) {
    if (selectionAnswerHistoryIndex < 0 || selectionAnswerHistoryIndex >= selectionAnswerHistory.length) return

    const selectionAnswerHistoryItem = selectionAnswerHistory[selectionAnswerHistoryIndex]
    if (!selectionAnswerHistoryItem) return

    setSelectionAnswerHistory(selectionAnswerHistory.slice(0, selectionAnswerHistoryIndex))

    const historyIndex = history.indexOf(selectionAnswerHistoryItem.publishedSlideId)
    if (historyIndex !== -1) {
      onViewEnd()
      setHistory(history.slice(0, historyIndex + 1))
    }
  }

  function goBackToBeginning() {
    // 先頭の動画スライドを再生中の場合、リプレイをトリガーする
    if (history.length === 1 && currentSlide?.type === SlideType.VIDEO) {
      replayEventTargetRef.current.dispatchEvent(new Event('replay'))
      return
    } else {
      // そうでない場合、先頭へ戻る
      setSelectionAnswerHistory([])
      onViewEnd()
      // 設問回答履歴をリセット
      setHistory(getInitialHistory())
    }
  }

  const sendGaEvent = useCallback(
    (eventName: string, params: Record<string, string>) => {
      if (publishedScenario.googleAnalyticsMeasurementId) {
        if (window.gtag) {
          window.gtag('event', eventName, params)
        } else if ('dataLayer' in window && Array.isArray(window.dataLayer)) {
          window.dataLayer.push({ event: eventName, ...params })
        }
      }
    },
    [publishedScenario],
  )

  const recordViewingTime = useCallback(async () => {
    if (leadSession && currentSlide && isNumber(viewingTimeRef.current)) {
      // LOOV イベントの送信
      await createLeadSessionEvent({
        leadSessionId: leadSession.id,
        type: LeadSessionEventType.VIDEO_VIEWING_TIME,
        publishedSlideId: currentSlide.id,
        viewingTime: viewingTimeRef.current,
      })
    }
  }, [currentSlide, leadSession])

  async function onViewStart() {
    // モーダルの開閉など、途中から視聴を再開した場合はスキップする
    if (isSlideViewingRef.current) {
      return
    }
    isSlideViewingRef.current = true

    if (leadSession && currentSlide) {
      // 親コンポーネントのコールバック実行
      parentOnViewStart?.(currentSlide)
      // GA イベントの送信
      sendGaEvent('loov_slide_view_start', {
        scenarioId: publishedScenario.scenarioId,
        scenarioName: publishedScenario.name,
        slideId: currentSlide.slideId,
        slideName: currentSlide.name,
        slideType: currentSlide.type,
      })

      // LOOV イベントの送信
      await createLeadSessionEvent({
        leadSessionId: leadSession.id,
        type: LeadSessionEventType.SLIDE_VIEW_START,
        publishedSlideId: currentSlide.id,
      })
    }
  }

  /**
   * スライドの視聴終了を記録する
   * 「戻る」をクリックした場合など、途中で視聴を終了したときのみ視聴時間を記録する
   */
  const onViewEnd = useCallback(
    ({ recordViewingTime = true }: { recordViewingTime?: boolean } = {}) => {
      if (leadSession && currentSlide) {
        // GA イベントの送信
        sendGaEvent('loov_slide_view_end', {
          scenarioId: publishedScenario.scenarioId,
          scenarioName: publishedScenario.name,
          slideId: currentSlide.slideId,
          slideName: currentSlide.name,
          slideType: currentSlide.type,
        })

        // LOOV イベントの送信
        const viewingTime = viewingTimeRef.current
        createLeadSessionEvent({
          leadSessionId: leadSession.id,
          type: LeadSessionEventType.SLIDE_VIEW_END,
          publishedSlideId: currentSlide.id,
          ...(recordViewingTime && isNumber(viewingTime) && { viewingTime }),
        })
      }

      isSlideViewingRef.current = false
      currentTimeRef.current = 0
      viewingTimeRef.current = undefined
    },
    [currentSlide, leadSession, publishedScenario, sendGaEvent],
  )

  async function onAnswerSelection(
    publishedSlide: PublishedSelectionSlide,
    publishedSelectionOption: PublishedSelectionOption,
  ) {
    // 設問回答履歴に挿入
    setSelectionAnswerHistory([
      ...selectionAnswerHistory,
      {
        publishedSlideId: publishedSlide.id,
        publishedSelectionSlideContentText: publishedSlide.publishedSelectionSlideContent.text,
        publishedSelectionOptionValue: publishedSelectionOption.value,
      },
    ])

    if (!leadSession) {
      return
    }

    // GA イベントの送信
    sendGaEvent('loov_submit_selection', {
      scenarioId: publishedScenario.scenarioId,
      scenarioName: publishedScenario.name,
      slideId: publishedSlide.slideId,
      slideName: publishedSlide.name,
      selectionText: publishedSlide.publishedSelectionSlideContent.text,
      selectedValue: publishedSelectionOption.value,
    })

    // LOOV イベントの送信
    await createLeadSessionEvent({
      leadSessionId: leadSession.id,
      type: 'SELECTION_ANSWER',
      publishedSelectionOptionId: publishedSelectionOption.id,
    })
  }

  async function onAnswerForm(publishedSlide: PublishedFormSlide, data: Record<string, string | string[]>) {
    // すでに回答済みの場合は処理を行わない
    if (answeredFormSlideIdsSetRef.current.has(publishedSlide.slideId)) {
      return
    }

    // 再視聴時に回答済みのフォームをスキップするため、フォームの情報を保持
    answeredFormSlideIdsSetRef.current.add(publishedSlide.slideId)

    if (!leadSession) {
      return
    }

    const publishedFormInputs = publishedSlide.publishedFormSlideContent.publishedFormInputs

    // イベント送信
    // LOOV イベントのパラメータ
    const formValues: FormValue[] = []

    for (const formInput of publishedFormInputs) {
      const value = data[formInput.id]
      if (value) {
        if (formInput.type === FormInputType.TEXT || formInput.type === FormInputType.EMAIL) {
          // テキスト入力
          if (isString(value)) {
            formValues.push({
              publishedFormInputId: formInput.id,
              text: value,
            })
          }
        } else if (formInput.type === FormInputType.SELECT) {
          // 単一選択
          if (isString(value)) {
            formValues.push({
              publishedFormInputId: formInput.id,
              publishedFormInputOptionIds: [value],
            })
          }
        } else if (formInput.type === FormInputType.MULTI_SELECT) {
          // 複数選択
          if (isArray(value)) {
            formValues.push({
              publishedFormInputId: formInput.id,
              publishedFormInputOptionIds: value,
            })
          }
        } else {
          assertNever(formInput.type)
        }
      }
    }

    // GA イベントの送信
    sendGaEvent('loov_submit_form', {
      scenarioId: publishedScenario.scenarioId,
      scenarioName: publishedScenario.name,
      slideId: publishedSlide.slideId,
      slideName: publishedSlide.name,
    })

    // LOOV イベントの送信
    await createLeadSessionEvent({
      type: LeadSessionEventType.FORM_ANSWER,
      leadSessionId: leadSession.id,
      publishedSlideId: publishedSlide.id,
      formValues,
    })
  }

  return (
    <ScenarioPlayerContext.Provider
      value={{
        publishedScenario,
        currentSlide,
        replayEventTarget: replayEventTargetRef.current,
        currentTime: currentTimeRef.current,
        playbackRate: playbackRateRef.current,
        volume: volumeRef.current,
        setCurrentTime,
        setPlaybackRate,
        setVolume,
        goNext,
        goBack,
        goBackToSelectionSlide,
        goBackToBeginning,
        onViewStart,
        recordViewingTime,
        onAnswerSelection,
        onAnswerForm,
        selectionAnswerHistory,
      }}
    >
      {children}
    </ScenarioPlayerContext.Provider>
  )
}

type GetLeadAndSessionType = {
  publishedScenarioId: string
  preview?: boolean
  overrideLeadId?: string
  leadProfileId?: string
  needsNotify?: boolean
}
async function getLeadAndSession({
  publishedScenarioId,
  preview,
  overrideLeadId,
  leadProfileId,
  needsNotify,
}: GetLeadAndSessionType) {
  if (preview) {
    return {}
  }

  // leadId は、propsで指定されているか、localStorage に保持されているものを使う
  const storageLeadId = localStorage.getItem(LocalStorageKey.LEAD_ID)
  const leadId = overrideLeadId ?? storageLeadId ?? undefined
  const lead = await getOrCreateLead(leadId)
  localStorage.setItem(LocalStorageKey.LEAD_ID, lead.id)

  // リードプロファイルが指定されている場合は紐付ける
  if (leadProfileId) {
    await setLeadProfileToLead(lead.id, leadProfileId)
  }

  // prop と storage の両方に値があり、かつ違う値の場合、リードのマージを行う
  if (overrideLeadId && storageLeadId && overrideLeadId !== storageLeadId) {
    await mergeLead(storageLeadId, { destinationLeadId: overrideLeadId })
  }

  const leadSession = await createLeadSession({
    leadId: lead.id,
    publishedScenarioId,
    notify: needsNotify,
  })

  return { lead, leadSession }
}

async function getOrCreateLead(leadId?: string): Promise<LeadDetail> {
  if (!leadId) {
    return createLead()
  }

  try {
    return await getLead(leadId)
  } catch (e) {
    // TODO: ↓fetchはリダイレクトを自動的に取得するらしいのでいらないかも？調査する
    // リードがマージ済みの場合には 308 が返されることがあるので、リダイレクト先を取得する
    if (e instanceof ApiError && e.response.status === 308) {
      const data = await e.response.json()
      const nextLeadId = data.mergedTo
      if (nextLeadId) {
        return getOrCreateLead(nextLeadId)
      }
    }

    // その他のエラーの場合は新しく作成する
    return createLead()
  }
}

export default ScenarioPlayerProvider
