/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * loov-api
 */
import { loovPrivateEndpointClient } from './loovClient'
export type GetVideoSlideContentBySlideIdParams = {
  slideId: Cuid
}

export type ListVideoSlideContentParams = {
  videoId?: Cuid
}

export type GetVideoParams = {
  /**
   * URL の有効期限（秒）
   */
  urlExpiresIn?: number
}

export type ListVideosParams = {
  page?: number
  perPage?: number
}

export type ListVideoScenesParams = {
  videoRecordingId: string
}

export type ListSlidesParams = {
  scenarioId: string
}

export type GetSelectionSlideContentBySlideIdParams = {
  slideId: string
}

export type ListSelectionOptionsParams = {
  selectionSlideContentId: string
}

export type GetDailyScenarioStatsParams = {
  from: string
  to: string
}

export type GetScenarioStatsSummaryParams = {
  from: string
  to: string
}

export type ListScenarioNotificationsParams = {
  scenarioId: string
}

export type ListScenarioNotificationConditionsParams = {
  scenarioNotificationId: Cuid
}

export type ListLeadSessionsParams = {
  scenarioId: string
  hasAttribute?: boolean
  page?: number
  perPage?: number
}

export type ListLeadProfilesParams = {
  batchOperationId?: string
}

export type GetFormSlideContentBySlideIdParams = {
  slideId: string
}

export type ListFormInputsParams = {
  formSlideContentId: string
}

export type ListFormInputOptionsParams = {
  formInputId: string
}

export type ExportSelectionAnswersSummaryParams = {
  scenarioId: string
  from?: string
  to?: string
}

export type ExportLeadSessionsParams = {
  scenarioId: string
  hasAttribute?: boolean
  from?: string
  to?: string
}

export type CreateApiKey200AllOf = {
  rawKey: string
}

export type CreateApiKey200 = ApiKey & CreateApiKey200AllOf

export type UpdateVideoSlideContentInput = PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrl

/**
 * @nullable
 */
export type PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrlVideoId = Cuid | null

/**
 * @nullable
 */
export type PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrlNextUrl = Url | null

/**
 * @nullable
 */
export type PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrlNextSlideId = Cuid | null

/**
 * Make all properties in T optional
 */
export interface PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrl {
  /** @nullable */
  nextSlideId?: PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrlNextSlideId
  /** @nullable */
  nextUrl?: PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrlNextUrl
  /** @nullable */
  videoId?: PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrlVideoId
}

export type SetVideoCaptionInputItem = {
  end: number
  start: number
  text: string
}

export type SetVideoCaptionInput = SetVideoCaptionInputItem[]

/**
 * @nullable
 */
export type UpdateVideoInputVideoRecordingVideoAvatorId = Cuid | null

export type UpdateVideoInputVideoRecording = {
  faceIconLocationType?: FaceIconLocationType
  pitch?: number
  speakerType?: SpeakerType
  speakingRate?: number
  /** @nullable */
  videoAvatorId?: UpdateVideoInputVideoRecordingVideoAvatorId
  voiceLanguageCode?: string
  voiceName?: string
  volumeGainDb?: number
}

export type UpdateVideoInputStatus = (typeof UpdateVideoInputStatus)[keyof typeof UpdateVideoInputStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateVideoInputStatus = {
  UPLOADING: 'UPLOADING',
  ERROR: 'ERROR',
} as const

export interface UpdateVideoInput {
  name?: string
  status?: UpdateVideoInputStatus
  videoRecording?: UpdateVideoInputVideoRecording
}

export type CreateVideoInputVideoRecordingAnyOfThree = {
  copySourceId: string
}

/**
 * @nullable
 */
export type CreateVideoInputVideoRecording =
  | CreateVideoInputVideoRecordingAnyOf
  | CreateVideoInputVideoRecordingAnyOfThree
  | null

export interface CreateVideoInput {
  convertOptions?: CreateVideoInputConvertOptions
  name: string
  status?: CreateVideoInputStatus
  /** @nullable */
  videoRecording?: CreateVideoInputVideoRecording
}

/**
 * @nullable
 */
export type CreateVideoInputVideoRecordingAnyOfVideoAvatorId = Cuid | null

export type CreateVideoInputVideoRecordingAnyOf = {
  faceIconLocationType: FaceIconLocationType
  pitch: number
  speakerType: SpeakerType
  speakingRate: number
  /** @nullable */
  videoAvatorId?: CreateVideoInputVideoRecordingAnyOfVideoAvatorId
  voiceLanguageCode: string
  voiceName: string
  volumeGainDb: number
}

export type CreateVideoInputStatus = (typeof CreateVideoInputStatus)[keyof typeof CreateVideoInputStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateVideoInputStatus = {
  UPLOADING: 'UPLOADING',
  RECORDING: 'RECORDING',
} as const

export interface ClippingRange {
  endTimecode: string
  startTimecode: string
}

export type CreateVideoInputConvertOptions = {
  clippingRanges: ClippingRange[]
}

export type CreateVideoOutputAllOfStatus =
  (typeof CreateVideoOutputAllOfStatus)[keyof typeof CreateVideoOutputAllOfStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateVideoOutputAllOfStatus = {
  UPLOADING: 'UPLOADING',
  RECORDING: 'RECORDING',
} as const

export type CreateVideoOutputAllOf = {
  status: CreateVideoOutputAllOfStatus
}

export type CreateVideoOutput = VideoWithUrl & CreateVideoOutputAllOf

export interface UpdateVideoSceneInput {
  description?: string
  position?: number
}

export type WithUrlVideoSceneAllOf = {
  url: Url
}

export type WithUrlVideoScene = ReadonlyVideoScenePropsAndDomainModelMetaData & WithUrlVideoSceneAllOf

export interface CreateVideoSceneInput {
  description: string
  position: number
  videoRecordingId: Cuid
}

export type WithUploadUrlVideoSceneAllOf = {
  uploadUrl: Url
}

/**
 * Make all properties in T readonly
 */
export interface ReadonlyVideoScenePropsAndDomainModelMetaData {
  createdAt: string
  description: string
  id: string
  position: number
  updatedAt: string
  videoRecordingId: string
}

export type WithUploadUrlVideoScene = ReadonlyVideoScenePropsAndDomainModelMetaData & WithUploadUrlVideoSceneAllOf

export type VideoScene = ReadonlyVideoScenePropsAndDomainModelMetaData

export type DomainModelVideoSceneProps = ReadonlyVideoScenePropsAndDomainModelMetaData

export type WithUrlVideoAvatorAllOf = {
  url: Url
}

export type WithUploadUrlVideoAvatorAllOf = {
  uploadUrl: Url
}

export interface VideoAvator {
  createdAt: string
  id: Cuid
  updatedAt: string
}

export type WithUrlVideoAvator = VideoAvator & WithUrlVideoAvatorAllOf

export type WithUploadUrlVideoAvator = VideoAvator & WithUploadUrlVideoAvatorAllOf

export type Role = (typeof Role)[keyof typeof Role]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Role = {
  READ_ONLY: 'READ_ONLY',
  READ_WRITE: 'READ_WRITE',
} as const

export interface UpdateUserInput {
  role: Role
}

export type UserAllOf = {
  email: Email
  role: Role
}

export type User = SimpleUser & UserAllOf

export interface CreateUserInput {
  email: Email
  firstName: string
  lastName: string
  role: Role
}

export type UserEventData = UserEventDataBase & UserEventDataAllOf

export type CreateUserEventInput = UserEventData

export type UserEventScenarioUnpublishDataData = {
  publishedTotalCount?: number
  scenarioId: string
}

export type UserEventTypeSCENARIOUNPUBLISH =
  (typeof UserEventTypeSCENARIOUNPUBLISH)[keyof typeof UserEventTypeSCENARIOUNPUBLISH]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserEventTypeSCENARIOUNPUBLISH = {
  SCENARIO_UNPUBLISH: 'SCENARIO_UNPUBLISH',
} as const

export interface UserEventScenarioUnpublishData {
  data: UserEventScenarioUnpublishDataData
  type: UserEventTypeSCENARIOUNPUBLISH
}

export type UserEventScenarioPublishDataData = {
  publishedTotalCount?: number
  scenarioId: string
}

export type UserEventTypeSCENARIOPUBLISH =
  (typeof UserEventTypeSCENARIOPUBLISH)[keyof typeof UserEventTypeSCENARIOPUBLISH]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserEventTypeSCENARIOPUBLISH = {
  SCENARIO_PUBLISH: 'SCENARIO_PUBLISH',
} as const

export interface UserEventScenarioPublishData {
  data: UserEventScenarioPublishDataData
  type: UserEventTypeSCENARIOPUBLISH
}

/**
 * @nullable
 */
export type UserEventAccessTokenRefreshDataData =
  | (typeof UserEventAccessTokenRefreshDataData)[keyof typeof UserEventAccessTokenRefreshDataData]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserEventAccessTokenRefreshDataData = {} as const

export type UserEventTypeACCESSTOKENREFRESH =
  (typeof UserEventTypeACCESSTOKENREFRESH)[keyof typeof UserEventTypeACCESSTOKENREFRESH]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserEventTypeACCESSTOKENREFRESH = {
  ACCESS_TOKEN_REFRESH: 'ACCESS_TOKEN_REFRESH',
} as const

export interface UserEventAccessTokenRefreshData {
  /** @nullable */
  data: UserEventAccessTokenRefreshDataData
  type: UserEventTypeACCESSTOKENREFRESH
}

export type UserEventPageTransitionDataData = {
  url: string
}

export type UserEventTypePAGETRANSITION = (typeof UserEventTypePAGETRANSITION)[keyof typeof UserEventTypePAGETRANSITION]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserEventTypePAGETRANSITION = {
  PAGE_TRANSITION: 'PAGE_TRANSITION',
} as const

export interface UserEventPageTransitionData {
  data: UserEventPageTransitionDataData
  type: UserEventTypePAGETRANSITION
}

/**
 * @nullable
 */
export type UserEventLoginDataData = (typeof UserEventLoginDataData)[keyof typeof UserEventLoginDataData] | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserEventLoginDataData = {} as const

export type UserEventTypeLOGIN = (typeof UserEventTypeLOGIN)[keyof typeof UserEventTypeLOGIN]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserEventTypeLOGIN = {
  LOGIN: 'LOGIN',
} as const

export interface UserEventLoginData {
  /** @nullable */
  data: UserEventLoginDataData
  type: UserEventTypeLOGIN
}

export type UserEventDataAllOf =
  | UserEventLoginData
  | UserEventPageTransitionData
  | UserEventAccessTokenRefreshData
  | UserEventScenarioPublishData
  | UserEventScenarioUnpublishData

export type UserEventType = (typeof UserEventType)[keyof typeof UserEventType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserEventType = {
  LOGIN: 'LOGIN',
  PAGE_TRANSITION: 'PAGE_TRANSITION',
  ACCESS_TOKEN_REFRESH: 'ACCESS_TOKEN_REFRESH',
  SCENARIO_PUBLISH: 'SCENARIO_PUBLISH',
  SCENARIO_UNPUBLISH: 'SCENARIO_UNPUBLISH',
} as const

export interface UserEventDataBase {
  data: unknown
  type: UserEventType
}

/**
 * Make all properties in T optional
 */
export interface PartialPickMeFirstNameOrLastNameOrEmail {
  email?: Email
  firstName?: string
  lastName?: string
}

export type UpdateMeInput = PartialPickMeFirstNameOrLastNameOrEmail

export type Permission = (typeof Permission)[keyof typeof Permission]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Permission = {
  UPDATE_TENANT: 'UPDATE_TENANT',
  GET_USER: 'GET_USER',
  CREATE_USER: 'CREATE_USER',
  UPDATE_USER: 'UPDATE_USER',
  DELETE_USER: 'DELETE_USER',
  CREATE_SCENARIO: 'CREATE_SCENARIO',
  UPDATE_SCENARIO: 'UPDATE_SCENARIO',
  DELETE_SCENARIO: 'DELETE_SCENARIO',
} as const

export type MeAllOf = {
  email: Email
  permissions: Permission[]
}

export interface SimpleUser {
  createdAt: string
  firstName: string
  id: Cuid
  lastName: string
  tenantId: Cuid
  updatedAt: string
}

export type Me = SimpleUser & MeAllOf

export type SynthesizeSpeechInput = TextToSpeechInput & SynthesizeSpeechInputAllOf

export type SynthesizeSpeechInputAllOfAudioConfigAllOfTwo = {
  /**
   * @minimum -20
   * @maximum 20
   */
  pitch?: number
  /**
   * @minimum 0.25
   * @maximum 4
   */
  speakingRate?: number
  /**
   * @minimum -96
   * @maximum 16
   */
  volumeGainDb?: number
}

export type SynthesizeSpeechInputAllOfAudioConfigAllOf = {
  pitch?: number
  speakingRate?: number
  volumeGainDb?: number
}

export type SynthesizeSpeechInputAllOfAudioConfig = SynthesizeSpeechInputAllOfAudioConfigAllOf &
  SynthesizeSpeechInputAllOfAudioConfigAllOfTwo

export type SynthesizeSpeechInputAllOf = {
  audioConfig?: SynthesizeSpeechInputAllOfAudioConfig
}

export type TextToSpeechInputAllOfFourAnyOfTwo = {
  text: string
}

export type TextToSpeechInputAllOfFourAnyOf = {
  ssml: string
}

export type TextToSpeechInputAllOfFour = TextToSpeechInputAllOfFourAnyOf | TextToSpeechInputAllOfFourAnyOfTwo

export type TextToSpeechInputAllOfVoice = {
  languageCode: string
  name: string
}

export type TextToSpeechInputAllOfAudioConfig = {
  pitch?: number
  speakingRate?: number
  volumeGainDb?: number
}

export type TextToSpeechInputAllOf = {
  audioConfig?: TextToSpeechInputAllOfAudioConfig
  voice: TextToSpeechInputAllOfVoice
}

export type TextToSpeechInput = TextToSpeechInputAllOf & TextToSpeechInputAllOfFour

/**
 * Make all properties in T optional
 */
export interface PartialPickTenantOrNameOrIsSalesforceIntegrationEnabledOrSalesforceMyDomainNameOrSalesforceClientIdOrSalesforceClientSecretOrSalesforceNotificationEmailOrIsHubspotIntegrationEnabledOrHubspotAccessTokenOrHubspotNotificationEmailOrSalesforceScenarioNameFieldNameOrSalesforceScenarioLeadSessionUrlFieldNameOrSalesforceScenarioLeadSessionIdFieldName {
  hubspotAccessToken?: string
  hubspotNotificationEmail?: Email
  isHubspotIntegrationEnabled?: boolean
  isSalesforceIntegrationEnabled?: boolean
  name?: string
  salesforceClientId?: string
  salesforceClientSecret?: string
  salesforceMyDomainName?: string
  salesforceNotificationEmail?: Email
  salesforceScenarioLeadSessionIdFieldName?: string
  salesforceScenarioLeadSessionUrlFieldName?: string
  salesforceScenarioNameFieldName?: string
}

export type UpdateTenantInput =
  PartialPickTenantOrNameOrIsSalesforceIntegrationEnabledOrSalesforceMyDomainNameOrSalesforceClientIdOrSalesforceClientSecretOrSalesforceNotificationEmailOrIsHubspotIntegrationEnabledOrHubspotAccessTokenOrHubspotNotificationEmailOrSalesforceScenarioNameFieldNameOrSalesforceScenarioLeadSessionUrlFieldNameOrSalesforceScenarioLeadSessionIdFieldName

/**
 * Enums
 */
export type FeatureType = (typeof FeatureType)[keyof typeof FeatureType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeatureType = {
  AI_SCENARIO_GENERATION: 'AI_SCENARIO_GENERATION',
  EXTERNAL_API_GENERATE_SCENARIO_URL: 'EXTERNAL_API_GENERATE_SCENARIO_URL',
} as const

export interface Feature {
  createdAt: string
  featureType: FeatureType
  id: Cuid
  tenantId: Cuid
  updatedAt: string
}

export interface Tenant {
  createdAt: string
  hubspotAccessToken: string
  hubspotNotificationEmail: Email
  id: Cuid
  isHubspotIntegrationEnabled: boolean
  isSalesforceIntegrationEnabled: boolean
  name: string
  salesforceClientId: string
  salesforceClientSecret: string
  salesforceMyDomainName: string
  salesforceNotificationEmail: Email
  salesforceScenarioLeadSessionIdFieldName: string
  salesforceScenarioLeadSessionUrlFieldName: string
  salesforceScenarioNameFieldName: string
  updatedAt: string
}

export interface UpdateSlideInput {
  memo?: string
  name?: string
  position?: number
}

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickSlideScenarioIdOrNameOrTypeOrPosition {
  name: string
  position: number
  scenarioId: Cuid
  type: SlideType
}

export type CreateSlideInput = PickSlideScenarioIdOrNameOrTypeOrPosition

/**
 * Make all properties in T optional
 */
export interface PartialPickSelectionSlideContentTextOrDirection {
  direction?: SelectionSlideDirection
  text?: string
}

export type UpdateSelectionSlideContentInput = PartialPickSelectionSlideContentTextOrDirection

/**
 * @nullable
 */
export type PartialPickSelectionOptionValueOrNextSlideIdOrNextUrlOrPositionNextUrl = Url | null

/**
 * @nullable
 */
export type PartialPickSelectionOptionValueOrNextSlideIdOrNextUrlOrPositionNextSlideId = Cuid | null

/**
 * Make all properties in T optional
 */
export interface PartialPickSelectionOptionValueOrNextSlideIdOrNextUrlOrPosition {
  /** @nullable */
  nextSlideId?: PartialPickSelectionOptionValueOrNextSlideIdOrNextUrlOrPositionNextSlideId
  /** @nullable */
  nextUrl?: PartialPickSelectionOptionValueOrNextSlideIdOrNextUrlOrPositionNextUrl
  position?: number
  value?: string
}

export type UpdateSelectionOptionInput = PartialPickSelectionOptionValueOrNextSlideIdOrNextUrlOrPosition

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickSelectionOptionSelectionSlideContentId {
  selectionSlideContentId: Cuid
}

export type CreateSelectionOptionInput = PickSelectionOptionSelectionSlideContentId

export interface CreateScenarioThumbnailInput {
  scenarioId: string
}

export type WithUploadUrlScenarioThumbnailAllOf = {
  uploadUrl: Url
}

export type WithUploadUrlScenarioThumbnail = ScenarioThumbnail & WithUploadUrlScenarioThumbnailAllOf

export type GenerateScenarioInputProduct = {
  description: string
  name: string
}

export type GenerateScenarioInputDocumentsItem = {
  data: string
  name: string
}

export interface GenerateScenarioInput {
  documents: GenerateScenarioInputDocumentsItem[]
  product: GenerateScenarioInputProduct
  referenceInformation: string
  referenceUrls: string[]
  requiredQuestions: string[]
  requirements: string
}

export type UpdateScenarioInput =
  PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrShowSelectionAnswerHistoryOrIsPublicOrIsAutoPlayEnabledOrScenarioPasswordOrDisableViewerTracking

/**
 * @nullable
 */
export type PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrShowSelectionAnswerHistoryOrIsPublicOrIsAutoPlayEnabledOrScenarioPasswordOrDisableViewerTrackingScenarioThumbnailId =
  Cuid | null

/**
 * @nullable
 */
export type PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrShowSelectionAnswerHistoryOrIsPublicOrIsAutoPlayEnabledOrScenarioPasswordOrDisableViewerTrackingScenarioMobileThumbnailId =
  Cuid | null

/**
 * @nullable
 */
export type PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrShowSelectionAnswerHistoryOrIsPublicOrIsAutoPlayEnabledOrScenarioPasswordOrDisableViewerTrackingScenarioLogoId =
  Cuid | null

/**
 * Make all properties in T optional
 */
export interface PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrShowSelectionAnswerHistoryOrIsPublicOrIsAutoPlayEnabledOrScenarioPasswordOrDisableViewerTracking {
  ctaColor?: string
  ctaFontSize?: number
  ctaText?: string
  ctaUrl?: Url
  disableViewerTracking?: boolean
  googleAnalyticsMeasurementId?: string
  introductionCompanyName?: string
  introductionDescription?: string
  introductionTitle?: string
  isAutoPlayEnabled?: boolean
  isGoBackButtonEnabled?: boolean
  isPublic?: boolean
  modalDescription?: string
  name?: string
  /** @nullable */
  scenarioLogoId?: PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrShowSelectionAnswerHistoryOrIsPublicOrIsAutoPlayEnabledOrScenarioPasswordOrDisableViewerTrackingScenarioLogoId
  /** @nullable */
  scenarioMobileThumbnailId?: PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrShowSelectionAnswerHistoryOrIsPublicOrIsAutoPlayEnabledOrScenarioPasswordOrDisableViewerTrackingScenarioMobileThumbnailId
  scenarioPassword?: string
  /** @nullable */
  scenarioThumbnailId?: PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrShowSelectionAnswerHistoryOrIsPublicOrIsAutoPlayEnabledOrScenarioPasswordOrDisableViewerTrackingScenarioThumbnailId
  showSelectionAnswerHistory?: boolean
}

export type DailyScenarioStatsAllOf = {
  date: string
}

export type DailyScenarioStats = ScenarioStats & DailyScenarioStatsAllOf

export interface SelectionOptionStats {
  id: string
  numSelected: number
  position: number
  value: string
}

export interface SlideStats {
  /** @nullable */
  avgWatchVideoTime: number | null
  id: string
  name: string
  numViewComplete: number
  numViewStart: number
  position: number
  /** @nullable */
  selectionOptionStats: SelectionOptionStats[] | null
  type: SlideType
}

export type ScenarioStatsSummaryAllOf = {
  slideStats: SlideStats[]
}

export interface ScenarioStats {
  numFormSubmissions: number
  numViewers: number
  numVisitors: number
}

export type ScenarioStatsSummary = ScenarioStats & ScenarioStatsSummaryAllOf

export type ScenarioDetail = Scenario & ScenarioDetailAllOf

/**
 * @nullable
 */
export type ScenarioDetailAllOfScenarioThumbnail = WithUrlScenarioThumbnail | null

/**
 * @nullable
 */
export type ScenarioDetailAllOfScenarioLogo = WithUrlScenarioLogo | null

export type WithUrlScenarioThumbnailAllOf = {
  url: Url
}

export interface ScenarioThumbnail {
  createdAt: string
  id: Cuid
  tenantId: Cuid
  updatedAt: string
}

export type WithUrlScenarioThumbnail = ScenarioThumbnail & WithUrlScenarioThumbnailAllOf

export type ComplexSlide = VideoSlide | SelectionSlide | FormSlide

export type ScenarioDetailAllOf = {
  /** @nullable */
  lastPublishedAt: string | null
  /** @nullable */
  scenarioLogo: ScenarioDetailAllOfScenarioLogo
  /** @nullable */
  scenarioThumbnail: ScenarioDetailAllOfScenarioThumbnail
  slides: ComplexSlide[]
}

/**
 * @nullable
 */
export type FormSlideAllOfVideoSlideContent =
  | (typeof FormSlideAllOfVideoSlideContent)[keyof typeof FormSlideAllOfVideoSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormSlideAllOfVideoSlideContent = {} as const

/**
 */
export type FormSlideAllOfType = (typeof FormSlideAllOfType)[keyof typeof FormSlideAllOfType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormSlideAllOfType = {
  FORM: 'FORM',
} as const

/**
 * @nullable
 */
export type FormSlideAllOfSelectionSlideContent =
  | (typeof FormSlideAllOfSelectionSlideContent)[keyof typeof FormSlideAllOfSelectionSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormSlideAllOfSelectionSlideContent = {} as const

export type JoinedFormSlideContent = FormSlideContent & JoinedFormSlideContentAllOf

export type FormSlideAllOf = {
  formSlideContent: JoinedFormSlideContent
  /** @nullable */
  selectionSlideContent: FormSlideAllOfSelectionSlideContent
  /** */
  type: FormSlideAllOfType
  /** @nullable */
  videoSlideContent: FormSlideAllOfVideoSlideContent
}

export type FormSlide = Slide & FormSlideAllOf

/**
 * @nullable
 */
export type JoinedFormSlideContentAllOfFormThumbnail = WithUrlFormThumbnail | null

export type JoinedFormSlideContentAllOfFormInputsItemAllOf = {
  options: FormInputOption[]
}

export type JoinedFormSlideContentAllOfFormInputsItem = FormInput & JoinedFormSlideContentAllOfFormInputsItemAllOf

export type JoinedFormSlideContentAllOf = {
  formInputs: JoinedFormSlideContentAllOfFormInputsItem[]
  /** @nullable */
  formThumbnail: JoinedFormSlideContentAllOfFormThumbnail
}

export type SelectionSlide = Slide & SelectionSlideAllOf

/**
 * @nullable
 */
export type SelectionSlideAllOfVideoSlideContent =
  | (typeof SelectionSlideAllOfVideoSlideContent)[keyof typeof SelectionSlideAllOfVideoSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectionSlideAllOfVideoSlideContent = {} as const

/**
 */
export type SelectionSlideAllOfType = (typeof SelectionSlideAllOfType)[keyof typeof SelectionSlideAllOfType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectionSlideAllOfType = {
  SELECTION: 'SELECTION',
} as const

/**
 * @nullable
 */
export type SelectionSlideAllOfFormSlideContent =
  | (typeof SelectionSlideAllOfFormSlideContent)[keyof typeof SelectionSlideAllOfFormSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectionSlideAllOfFormSlideContent = {} as const

export type JoinedSelectionSlideContent = SelectionSlideContent & JoinedSelectionSlideContentAllOf

export type SelectionSlideAllOf = {
  /** @nullable */
  formSlideContent: SelectionSlideAllOfFormSlideContent
  selectionSlideContent: JoinedSelectionSlideContent
  /** */
  type: SelectionSlideAllOfType
  /** @nullable */
  videoSlideContent: SelectionSlideAllOfVideoSlideContent
}

/**
 * @nullable
 */
export type SelectionOptionNextUrl = Url | null

/**
 * @nullable
 */
export type SelectionOptionNextSlideId = Cuid | null

export interface SelectionOption {
  createdAt: string
  id: Cuid
  /** @nullable */
  nextSlideId: SelectionOptionNextSlideId
  /** @nullable */
  nextUrl: SelectionOptionNextUrl
  position: number
  selectionSlideContentId: Cuid
  updatedAt: string
  value: string
}

export type JoinedSelectionSlideContentAllOf = {
  selectionOptions: SelectionOption[]
}

export interface SelectionSlideContent {
  createdAt: string
  direction: SelectionSlideDirection
  id: Cuid
  slideId: Cuid
  text: string
  updatedAt: string
}

export type VideoSlide = Slide & VideoSlideAllOf

/**
 */
export type VideoSlideAllOfType = (typeof VideoSlideAllOfType)[keyof typeof VideoSlideAllOfType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoSlideAllOfType = {
  VIDEO: 'VIDEO',
} as const

/**
 * @nullable
 */
export type VideoSlideAllOfSelectionSlideContent =
  | (typeof VideoSlideAllOfSelectionSlideContent)[keyof typeof VideoSlideAllOfSelectionSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoSlideAllOfSelectionSlideContent = {} as const

/**
 * @nullable
 */
export type VideoSlideAllOfFormSlideContent =
  | (typeof VideoSlideAllOfFormSlideContent)[keyof typeof VideoSlideAllOfFormSlideContent]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoSlideAllOfFormSlideContent = {} as const

export type VideoSlideAllOf = {
  /** @nullable */
  formSlideContent: VideoSlideAllOfFormSlideContent
  /** @nullable */
  selectionSlideContent: VideoSlideAllOfSelectionSlideContent
  /** */
  type: VideoSlideAllOfType
  videoSlideContent: JoinedVideoSlideContent
}

export interface VideoCaptionSegment {
  createdAt: string
  end: number
  id: Cuid
  start: number
  text: string
  updatedAt: string
  videoId: Cuid
}

export type JoinedVideoSlideContentAllOfVideoAllOf = {
  caption: VideoCaptionSegment[]
}

/**
 * @nullable
 */
export type JoinedVideoSlideContentAllOfVideo = (VideoWithUrl & JoinedVideoSlideContentAllOfVideoAllOf) | null

export type JoinedVideoSlideContentAllOf = {
  /** @nullable */
  video: JoinedVideoSlideContentAllOfVideo
}

export type JoinedVideoSlideContent = VideoSlideContent & JoinedVideoSlideContentAllOf

export type WithUploadUrlUploadingVideoAllOf = {
  uploadUrl: Url
}

export type WithUploadUrlUploadingVideo = UploadingVideo & WithUploadUrlUploadingVideoAllOf

export type VideoWithUrl =
  | WithThumbnailUrlAvailableVideo
  | WithUploadUrlUploadingVideo
  | ProcessingVideo
  | RecordingVideo
  | ErrorVideo

export type WithThumbnailUrlAvailableVideoAllOf = {
  animatedThumbnailUrl: Url
  thumbnailUrl: Url
}

export type WithUrlAvailableVideoAllOf = {
  url: Url
}

export type WithUrlAvailableVideo = AvailableVideo & WithUrlAvailableVideoAllOf

export type WithThumbnailUrlAvailableVideo = WithUrlAvailableVideo & WithThumbnailUrlAvailableVideoAllOf

/**
 * @nullable
 */
export type VideoSlideContentVideoId = Cuid | null

/**
 * @nullable
 */
export type VideoSlideContentNextUrl = Url | null

/**
 * @nullable
 */
export type VideoSlideContentNextSlideId = Cuid | null

export interface Slide {
  createdAt: string
  id: Cuid
  memo: string
  name: string
  position: number
  scenarioId: Cuid
  type: SlideType
  updatedAt: string
}

export type CopyScenarioInputAllOfCopyOptions = {
  sourceId: Cuid
}

export type CopyScenarioInputAllOf = {
  copyOptions: CopyScenarioInputAllOfCopyOptions
}

export type CopyScenarioInput = PickScenarioNameOrType & CopyScenarioInputAllOf

export type CreateScenarioFromScratchInputAllOf = {
  slides?: CreateScenarioSlideInput[]
}

export type CreateScenarioFromScratchInput = PickScenarioNameOrType & CreateScenarioFromScratchInputAllOf

export type CreateScenarioInput = CreateScenarioFromScratchInput | CopyScenarioInput

/**
 */
export type CreateScenarioFormSlideInputType =
  (typeof CreateScenarioFormSlideInputType)[keyof typeof CreateScenarioFormSlideInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateScenarioFormSlideInputType = {
  FORM: 'FORM',
} as const

export type CreateScenarioFormSlideInputFormInputsItem = {
  name: string
  placeholder: string
  required: boolean
  type: FormInputType
}

export interface CreateScenarioFormSlideInput {
  buttonText?: string
  description?: string
  design?: FormDesign
  formInputs?: CreateScenarioFormSlideInputFormInputsItem[]
  name: string
  nextSlideIndex?: number
  nextUrl?: string
  /** */
  type: CreateScenarioFormSlideInputType
}

export type CreateScenarioSlideInput =
  | CreateScenarioVideoSlideInput
  | CreateScenarioSelectionSlideInput
  | CreateScenarioFormSlideInput

/**
 */
export type CreateScenarioSelectionSlideInputType =
  (typeof CreateScenarioSelectionSlideInputType)[keyof typeof CreateScenarioSelectionSlideInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateScenarioSelectionSlideInputType = {
  SELECTION: 'SELECTION',
} as const

export type CreateScenarioSelectionSlideInputOptionsItem = {
  nextSlideIndex?: number
  nextUrl?: string
  value?: string
}

export interface CreateScenarioSelectionSlideInput {
  name: string
  options?: CreateScenarioSelectionSlideInputOptionsItem[]
  text?: string
  /** */
  type: CreateScenarioSelectionSlideInputType
}

/**
 */
export type CreateScenarioVideoSlideInputType =
  (typeof CreateScenarioVideoSlideInputType)[keyof typeof CreateScenarioVideoSlideInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateScenarioVideoSlideInputType = {
  VIDEO: 'VIDEO',
} as const

export interface CreateScenarioVideoSlideInput {
  name: string
  nextSlideIndex?: number
  nextUrl?: string
  /** */
  type: CreateScenarioVideoSlideInputType
}

export type CreateScenarioInputBase = PickScenarioNameOrType

/**
 * @nullable
 */
export type ScenarioScenarioThumbnailId = Cuid | null

/**
 * @nullable
 */
export type ScenarioScenarioMobileThumbnailId = Cuid | null

/**
 * @nullable
 */
export type ScenarioScenarioLogoId = Cuid | null

export type ScenarioType = (typeof ScenarioType)[keyof typeof ScenarioType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScenarioType = {
  PAGE: 'PAGE',
  EMBEDDED: 'EMBEDDED',
} as const

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickScenarioNameOrType {
  name: string
  type: ScenarioType
}

export interface Scenario {
  contentUpdatedAt: string
  createdAt: string
  ctaColor: string
  ctaFontSize: number
  ctaText: string
  ctaUrl: Url
  disableViewerTracking: boolean
  googleAnalyticsMeasurementId: string
  id: Cuid
  introductionCompanyName: string
  introductionDescription: string
  introductionTitle: string
  isAutoPlayEnabled: boolean
  isGoBackButtonEnabled: boolean
  isPublic: boolean
  /** @nullable */
  lastPublishedAt: string | null
  modalDescription: string
  name: string
  /** @nullable */
  scenarioLogoId: ScenarioScenarioLogoId
  /** @nullable */
  scenarioMobileThumbnailId: ScenarioScenarioMobileThumbnailId
  scenarioPassword: string
  /** @nullable */
  scenarioThumbnailId: ScenarioScenarioThumbnailId
  showSelectionAnswerHistory: boolean
  tenantId: Cuid
  type: ScenarioType
  updatedAt: string
}

/**
 * Make all properties in T optional
 */
export interface PartialPickScenarioNotificationNameOrRecipientEmailsOrNotifyProfiledLeadOnly {
  name?: string
  notifyProfiledLeadOnly?: boolean
  recipientEmails?: string[]
}

export type UpdateScenarioNotificationInput =
  PartialPickScenarioNotificationNameOrRecipientEmailsOrNotifyProfiledLeadOnly

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickScenarioNotificationScenarioIdOrNameOrRecipientEmailsOrNotifyProfiledLeadOnly {
  name: string
  notifyProfiledLeadOnly: boolean
  recipientEmails: string[]
  scenarioId: string
}

export type CreateScenarioNotificationInput =
  PickScenarioNotificationScenarioIdOrNameOrRecipientEmailsOrNotifyProfiledLeadOnly

/**
 * Make all properties in T readonly
 */
export interface ReadonlyScenarioIdStringname3AstringrecipientEmailsStringArraynotifyProfiledLeadOnlyBooleanAndDomainModelMetaData {
  createdAt: string
  id: string
  name: string
  notifyProfiledLeadOnly: boolean
  recipientEmails: string[]
  scenarioId: string
  updatedAt: string
}

export type ScenarioNotification =
  ReadonlyScenarioIdStringname3AstringrecipientEmailsStringArraynotifyProfiledLeadOnlyBooleanAndDomainModelMetaData

export type DomainModelScenarioIdStringname3AstringrecipientEmailsStringArraynotifyProfiledLeadOnlyBoolean =
  ReadonlyScenarioIdStringname3AstringrecipientEmailsStringArraynotifyProfiledLeadOnlyBooleanAndDomainModelMetaData

export interface UpdateScenarioNotificationConditionInput {
  selectionOptionId?: string
  slideId: string
}

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickScenarioNotificationConditionScenarioNotificationId {
  scenarioNotificationId: string
}

export type CreateScenarioNotificationConditionInput = PickScenarioNotificationConditionScenarioNotificationId &
  UpdateScenarioNotificationConditionInput

/**
 */
export type ReadonlyScenarioNotificationIdStringtargetEvent3ASLIDEVIEWENDslideIdStringAndDomainModelMetaDataTargetEvent =
  (typeof ReadonlyScenarioNotificationIdStringtargetEvent3ASLIDEVIEWENDslideIdStringAndDomainModelMetaDataTargetEvent)[keyof typeof ReadonlyScenarioNotificationIdStringtargetEvent3ASLIDEVIEWENDslideIdStringAndDomainModelMetaDataTargetEvent]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReadonlyScenarioNotificationIdStringtargetEvent3ASLIDEVIEWENDslideIdStringAndDomainModelMetaDataTargetEvent =
  {
    SLIDE_VIEW_END: 'SLIDE_VIEW_END',
  } as const

/**
 * Make all properties in T readonly
 */
export interface ReadonlyScenarioNotificationIdStringtargetEvent3ASLIDEVIEWENDslideIdStringAndDomainModelMetaData {
  createdAt: string
  id: string
  scenarioNotificationId: string
  slideId: string
  /** */
  targetEvent: ReadonlyScenarioNotificationIdStringtargetEvent3ASLIDEVIEWENDslideIdStringAndDomainModelMetaDataTargetEvent
  updatedAt: string
}

export type ViewSlideNotification =
  ReadonlyScenarioNotificationIdStringtargetEvent3ASLIDEVIEWENDslideIdStringAndDomainModelMetaData

export type DomainModelScenarioNotificationIdStringtargetEvent3ASLIDEVIEWENDslideIdString =
  ReadonlyScenarioNotificationIdStringtargetEvent3ASLIDEVIEWENDslideIdStringAndDomainModelMetaData

/**
 */
export type ReadonlyScenarioNotificationIdStringtargetEvent3ASELECTIONANSWERslideIdStringselectionOptionId3AstringAndDomainModelMetaDataTargetEvent =
  (typeof ReadonlyScenarioNotificationIdStringtargetEvent3ASELECTIONANSWERslideIdStringselectionOptionId3AstringAndDomainModelMetaDataTargetEvent)[keyof typeof ReadonlyScenarioNotificationIdStringtargetEvent3ASELECTIONANSWERslideIdStringselectionOptionId3AstringAndDomainModelMetaDataTargetEvent]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReadonlyScenarioNotificationIdStringtargetEvent3ASELECTIONANSWERslideIdStringselectionOptionId3AstringAndDomainModelMetaDataTargetEvent =
  {
    SELECTION_ANSWER: 'SELECTION_ANSWER',
  } as const

/**
 * Make all properties in T readonly
 */
export interface ReadonlyScenarioNotificationIdStringtargetEvent3ASELECTIONANSWERslideIdStringselectionOptionId3AstringAndDomainModelMetaData {
  createdAt: string
  id: string
  scenarioNotificationId: string
  selectionOptionId: string
  slideId: string
  /** */
  targetEvent: ReadonlyScenarioNotificationIdStringtargetEvent3ASELECTIONANSWERslideIdStringselectionOptionId3AstringAndDomainModelMetaDataTargetEvent
  updatedAt: string
}

export type ScenarioNotificationCondition =
  | ReadonlyScenarioNotificationIdStringtargetEvent3ASELECTIONANSWERslideIdStringselectionOptionId3AstringAndDomainModelMetaData
  | ReadonlyScenarioNotificationIdStringtargetEvent3ASLIDEVIEWENDslideIdStringAndDomainModelMetaData

export type SelectOptionNotification =
  ReadonlyScenarioNotificationIdStringtargetEvent3ASELECTIONANSWERslideIdStringselectionOptionId3AstringAndDomainModelMetaData

export type DomainModelScenarioNotificationIdStringtargetEvent3ASELECTIONANSWERslideIdStringselectionOptionId3Astring =
  ReadonlyScenarioNotificationIdStringtargetEvent3ASELECTIONANSWERslideIdStringselectionOptionId3AstringAndDomainModelMetaData

export type WithUrlScenarioMobileThumbnailAllOf = {
  url: Url
}

export type WithUrlScenarioMobileThumbnail = ScenarioMobileThumbnail & WithUrlScenarioMobileThumbnailAllOf

export interface CreateScenarioMobileThumbnailInput {
  scenarioId: string
}

export type WithUploadUrlScenarioMobileThumbnailAllOf = {
  uploadUrl: Url
}

export interface ScenarioMobileThumbnail {
  createdAt: string
  id: Cuid
  tenantId: Cuid
  updatedAt: string
}

export type WithUploadUrlScenarioMobileThumbnail = ScenarioMobileThumbnail & WithUploadUrlScenarioMobileThumbnailAllOf

export type WithUrlScenarioLogoAllOf = {
  url: Url
}

export type WithUrlScenarioLogo = ScenarioLogo & WithUrlScenarioLogoAllOf

export interface CreateScenarioLogoInput {
  scenarioId: string
}

export type WithUploadUrlScenarioLogoAllOf = {
  uploadUrl: Url
}

export interface ScenarioLogo {
  createdAt: string
  id: Cuid
  tenantId: Cuid
  updatedAt: string
}

export type WithUploadUrlScenarioLogo = ScenarioLogo & WithUploadUrlScenarioLogoAllOf

export type LeadSessionEventDetailAllOf = {
  formAnswers: LeadSessionEventDetailAllOfFormAnswersItem[]
  /** @nullable */
  publishedSelectionOption: LeadSessionEventDetailAllOfPublishedSelectionOption
  /** @nullable */
  publishedSlide: LeadSessionEventDetailAllOfPublishedSlide
}

export type LeadSessionEventDetail = LeadSessionEvent & LeadSessionEventDetailAllOf

export type LeadSessionDetailAllOf = {
  leadSessionEvents: LeadSessionEventDetail[]
}

export type LeadSessionDetail = LeadSession & LeadSessionDetailAllOf

/**
 * @nullable
 */
export type LeadSessionEventDetailAllOfPublishedSlideAllOfPublishedVideoSlideContentAllOfVideo = Video | null

export type LeadSessionEventDetailAllOfPublishedSlideAllOfPublishedVideoSlideContentAllOf = {
  /** @nullable */
  video: LeadSessionEventDetailAllOfPublishedSlideAllOfPublishedVideoSlideContentAllOfVideo
}

/**
 * @nullable
 */
export type LeadSessionEventDetailAllOfPublishedSlideAllOfPublishedVideoSlideContent =
  | (PublishedVideoSlideContent & LeadSessionEventDetailAllOfPublishedSlideAllOfPublishedVideoSlideContentAllOf)
  | null

/**
 * @nullable
 */
export type LeadSessionEventDetailAllOfPublishedSlideAllOfPublishedFormSlideContent = PublishedFormSlideContent | null

export type LeadSessionEventDetailAllOfPublishedSlideAllOf = {
  /** @nullable */
  publishedFormSlideContent: LeadSessionEventDetailAllOfPublishedSlideAllOfPublishedFormSlideContent
  /** @nullable */
  publishedVideoSlideContent: LeadSessionEventDetailAllOfPublishedSlideAllOfPublishedVideoSlideContent
}

/**
 * @nullable
 */
export type LeadSessionEventDetailAllOfPublishedSlide =
  | (PublishedSlide & LeadSessionEventDetailAllOfPublishedSlideAllOf)
  | null

export type LeadSessionEventDetailAllOfPublishedSelectionOptionAllOf = {
  publishedSelectionSlideContent: PublishedSelectionSlideContent
}

/**
 * @nullable
 */
export type LeadSessionEventDetailAllOfPublishedSelectionOption =
  | (PublishedSelectionOption & LeadSessionEventDetailAllOfPublishedSelectionOptionAllOf)
  | null

export interface PublishedFormInputOption {
  createdAt: string
  formInputOptionId: Cuid
  id: Cuid
  label: string
  publishedFormInputId: Cuid
  updatedAt: string
  value: string
}

export type LeadSessionEventDetailAllOfFormAnswersItemAllOf = {
  publishedFormInput: PublishedFormInput
  publishedFormInputOptions: PublishedFormInputOption[]
}

export type LeadSessionEventDetailAllOfFormAnswersItem = FormAnswer & LeadSessionEventDetailAllOfFormAnswersItemAllOf

/**
 * @nullable
 */
export type PublishedFormInputSalesforceLeadProperty = SalesforceCreateLeadProperty | null

/**
 * @nullable
 */
export type PublishedFormInputHubspotContactProperty = HubspotCreateContactProperty | null

export interface PublishedFormInput {
  createdAt: string
  formInputId: Cuid
  /** @nullable */
  hubspotContactProperty: PublishedFormInputHubspotContactProperty
  id: Cuid
  name: string
  placeholder: string
  position: number
  publishedFormSlideContentId: Cuid
  required: boolean
  /** @nullable */
  salesforceLeadProperty: PublishedFormInputSalesforceLeadProperty
  type: FormInputType
  updatedAt: string
}

export interface FormAnswer {
  createdAt: string
  id: Cuid
  leadSessionEventId: Cuid
  publishedFormInputId: Cuid
  /** @nullable */
  text: string | null
  updatedAt: string
}

export type SelectionSlideDirection = (typeof SelectionSlideDirection)[keyof typeof SelectionSlideDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectionSlideDirection = {
  COLUMN: 'COLUMN',
  ROW: 'ROW',
} as const

export interface PublishedSelectionSlideContent {
  createdAt: string
  direction: SelectionSlideDirection
  id: Cuid
  publishedSlideId: Cuid
  text: string
  updatedAt: string
}

/**
 * @nullable
 */
export type PublishedSelectionOptionNextUrl = Url | null

/**
 * @nullable
 */
export type PublishedSelectionOptionNextPublishedSlideId = Cuid | null

export interface PublishedSelectionOption {
  createdAt: string
  id: Cuid
  /** @nullable */
  nextPublishedSlideId: PublishedSelectionOptionNextPublishedSlideId
  /** @nullable */
  nextUrl: PublishedSelectionOptionNextUrl
  position: number
  publishedSelectionSlideContentId: Cuid
  selectionOptionId: Cuid
  updatedAt: string
  value: string
}

/**
 * @nullable
 */
export type PublishedFormSlideContentNextUrl = Url | null

/**
 * @nullable
 */
export type PublishedFormSlideContentNextPublishedSlideId = Cuid | null

/**
 * @nullable
 */
export type PublishedFormSlideContentFormThumbnailId = Cuid | null

export interface PublishedFormSlideContent {
  buttonText: string
  createdAt: string
  description: string
  descriptionUnderThumbnail: string
  design: FormDesign
  /** @nullable */
  formThumbnailId: PublishedFormSlideContentFormThumbnailId
  id: Cuid
  /** @nullable */
  nextPublishedSlideId: PublishedFormSlideContentNextPublishedSlideId
  /** @nullable */
  nextUrl: PublishedFormSlideContentNextUrl
  notificationEmail: Email
  notifyWithFormAnswers: boolean
  privacyPolicyCheckboxText: string
  privacyPolicyText: string
  privacyPolicyUrl: Url
  publishedSlideId: Cuid
  salesforceCampaignId: string
  thanksMailBcc: string
  thanksMailBodyConclusion: string
  thanksMailBodyIntroduction: string
  thanksMailDisplayName: string
  thanksMailEnabled: boolean
  thanksMailSubject: string
  updatedAt: string
}

export type ErrorVideo = VideoBase & ErrorVideoAllOf

/**
 */
export type ErrorVideoAllOfStatus = (typeof ErrorVideoAllOfStatus)[keyof typeof ErrorVideoAllOfStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorVideoAllOfStatus = {
  ERROR: 'ERROR',
} as const

/**
 * @nullable
 */
export type ErrorVideoAllOfDuration = (typeof ErrorVideoAllOfDuration)[keyof typeof ErrorVideoAllOfDuration] | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorVideoAllOfDuration = {} as const

export type ErrorVideoAllOf = {
  /** @nullable */
  duration: ErrorVideoAllOfDuration
  /** */
  status: ErrorVideoAllOfStatus
}

export type RecordingVideo = VideoBase & RecordingVideoAllOf

export type Video = AvailableVideo | ProcessingVideo | UploadingVideo | RecordingVideo | ErrorVideo

/**
 */
export type RecordingVideoAllOfStatus = (typeof RecordingVideoAllOfStatus)[keyof typeof RecordingVideoAllOfStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecordingVideoAllOfStatus = {
  RECORDING: 'RECORDING',
} as const

/**
 * @nullable
 */
export type RecordingVideoAllOfDuration =
  | (typeof RecordingVideoAllOfDuration)[keyof typeof RecordingVideoAllOfDuration]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecordingVideoAllOfDuration = {} as const

export type RecordingVideoAllOf = {
  /** @nullable */
  duration: RecordingVideoAllOfDuration
  /** */
  status: RecordingVideoAllOfStatus
}

/**
 */
export type UploadingVideoAllOfStatus = (typeof UploadingVideoAllOfStatus)[keyof typeof UploadingVideoAllOfStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UploadingVideoAllOfStatus = {
  UPLOADING: 'UPLOADING',
} as const

/**
 * @nullable
 */
export type UploadingVideoAllOfDuration =
  | (typeof UploadingVideoAllOfDuration)[keyof typeof UploadingVideoAllOfDuration]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UploadingVideoAllOfDuration = {} as const

export type UploadingVideoAllOf = {
  /** @nullable */
  duration: UploadingVideoAllOfDuration
  /** */
  status: UploadingVideoAllOfStatus
}

/**
 */
export type ProcessingVideoAllOfStatus = (typeof ProcessingVideoAllOfStatus)[keyof typeof ProcessingVideoAllOfStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProcessingVideoAllOfStatus = {
  PROCESSING: 'PROCESSING',
} as const

/**
 * @nullable
 */
export type ProcessingVideoAllOfDuration =
  | (typeof ProcessingVideoAllOfDuration)[keyof typeof ProcessingVideoAllOfDuration]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProcessingVideoAllOfDuration = {} as const

export type ProcessingVideoAllOf = {
  /** @nullable */
  duration: ProcessingVideoAllOfDuration
  /** */
  status: ProcessingVideoAllOfStatus
}

export type ProcessingVideo = VideoBase & ProcessingVideoAllOf

/**
 */
export type AvailableVideoAllOfStatus = (typeof AvailableVideoAllOfStatus)[keyof typeof AvailableVideoAllOfStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AvailableVideoAllOfStatus = {
  AVAILABLE: 'AVAILABLE',
} as const

export type AvailableVideoAllOf = {
  duration: number
  /** */
  status: AvailableVideoAllOfStatus
}

export type AvailableVideo = VideoBase & AvailableVideoAllOf

/**
 * @nullable
 */
export type VideoBaseVideoRecording = VideoRecording | null

export interface VideoBase {
  createdAt: string
  /** @nullable */
  duration: number | null
  id: Cuid
  name: string
  status: VideoStatus
  tenantId: Cuid
  updatedAt: string
  /** @nullable */
  videoRecording: VideoBaseVideoRecording
}

export type UploadingVideo = VideoBase & UploadingVideoAllOf

/**
 * @nullable
 */
export type VideoRecordingVideoAvatorId = Cuid | null

export type FaceIconLocationType = (typeof FaceIconLocationType)[keyof typeof FaceIconLocationType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FaceIconLocationType = {
  INSIDE: 'INSIDE',
  OUTSIDE: 'OUTSIDE',
} as const

export type SpeakerType = (typeof SpeakerType)[keyof typeof SpeakerType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpeakerType = {
  USER: 'USER',
  MACHINE: 'MACHINE',
} as const

export interface VideoRecording {
  createdAt: string
  faceIconLocationType: FaceIconLocationType
  id: Cuid
  pitch: number
  speakerType: SpeakerType
  speakingRate: number
  updatedAt: string
  /** @nullable */
  videoAvatorId: VideoRecordingVideoAvatorId
  videoId: Cuid
  voiceLanguageCode: string
  voiceName: string
  volumeGainDb: number
}

export type VideoStatus = (typeof VideoStatus)[keyof typeof VideoStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VideoStatus = {
  ERROR: 'ERROR',
  AVAILABLE: 'AVAILABLE',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
  RECORDING: 'RECORDING',
} as const

/**
 * @nullable
 */
export type PublishedVideoSlideContentVideoId = Cuid | null

/**
 * @nullable
 */
export type PublishedVideoSlideContentNextUrl = Url | null

/**
 * @nullable
 */
export type PublishedVideoSlideContentNextPublishedSlideId = Cuid | null

export interface PublishedVideoSlideContent {
  createdAt: string
  id: Cuid
  /** @nullable */
  nextPublishedSlideId: PublishedVideoSlideContentNextPublishedSlideId
  /** @nullable */
  nextUrl: PublishedVideoSlideContentNextUrl
  publishedSlideId: Cuid
  updatedAt: string
  /** @nullable */
  videoId: PublishedVideoSlideContentVideoId
}

export type SlideType = (typeof SlideType)[keyof typeof SlideType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SlideType = {
  VIDEO: 'VIDEO',
  SELECTION: 'SELECTION',
  FORM: 'FORM',
} as const

export interface PublishedSlide {
  createdAt: string
  id: Cuid
  name: string
  position: number
  publishedScenarioId: Cuid
  slideId: Cuid
  type: SlideType
  updatedAt: string
}

/**
 * @nullable
 */
export type LeadSessionEventPublishedSlideId = Cuid | null

/**
 * @nullable
 */
export type LeadSessionEventPublishedSelectionOptionId = Cuid | null

export type LeadSessionEventType = (typeof LeadSessionEventType)[keyof typeof LeadSessionEventType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LeadSessionEventType = {
  SLIDE_VIEW_START: 'SLIDE_VIEW_START',
  SLIDE_VIEW_END: 'SLIDE_VIEW_END',
  SELECTION_ANSWER: 'SELECTION_ANSWER',
  FORM_ANSWER: 'FORM_ANSWER',
  VIDEO_VIEWING_TIME: 'VIDEO_VIEWING_TIME',
} as const

export interface LeadSessionEvent {
  createdAt: string
  id: Cuid
  leadSessionId: Cuid
  /** @nullable */
  publishedSelectionOptionId: LeadSessionEventPublishedSelectionOptionId
  /** @nullable */
  publishedSlideId: LeadSessionEventPublishedSlideId
  /** @nullable */
  text: string | null
  type: LeadSessionEventType
  updatedAt: string
  /** @nullable */
  viewingTime: number | null
}

export type ListLeadSessionsOutputItemAllOf = {
  lead: ListLeadSessionsOutputItemAllOfLead
}

export type ListLeadSessionsOutputItem = LeadSession & ListLeadSessionsOutputItemAllOf

export type ListLeadSessionsOutput = ListLeadSessionsOutputItem[]

export interface LeadAttribute {
  createdAt: string
  id: Cuid
  leadId: Cuid
  priority: number
  publishedScenarioId: Cuid
  updatedAt: string
  value: string
}

export type ListLeadSessionsOutputItemAllOfLeadAllOf = {
  leadAttributes: LeadAttribute[]
  leadProfiles: LeadProfile[]
}

export interface Lead {
  createdAt: string
  id: Cuid
  updatedAt: string
}

export type ListLeadSessionsOutputItemAllOfLead = Lead & ListLeadSessionsOutputItemAllOfLeadAllOf

export interface LeadSession {
  createdAt: string
  id: Cuid
  isRewatch: boolean
  leadId: Cuid
  publishedScenarioId: Cuid
  updatedAt: string
}

/**
 * @nullable
 */
export type CreateLeadProfileInputNotificationEmail = Email | null

/**
 * @nullable
 */
export type CreateLeadProfileInputEmail = Email | null

export interface CreateLeadProfileInput {
  /** @nullable */
  email?: CreateLeadProfileInputEmail
  /** @nullable */
  name?: string | null
  /** @nullable */
  notificationEmail?: CreateLeadProfileInputNotificationEmail
}

/**
 * @nullable
 */
export type LeadProfileNotificationEmail = Email | null

/**
 * @nullable
 */
export type LeadProfileEmail = Email | null

/**
 * @nullable
 */
export type LeadProfileBatchOperationId = Cuid | null

export interface LeadProfile {
  /** @nullable */
  batchOperationId: LeadProfileBatchOperationId
  createdAt: string
  /** @nullable */
  email: LeadProfileEmail
  id: Cuid
  /** @nullable */
  name: string | null
  /** @nullable */
  notificationEmail: LeadProfileNotificationEmail
  tenantId: Cuid
  updatedAt: string
}

export type WithUrlFormThumbnailAllOf = {
  url: Url
}

export type WithUrlFormThumbnail = FormThumbnail & WithUrlFormThumbnailAllOf

export interface CreateFormThumbnailInput {
  formSlideContentId: string
}

export type WithUploadUrlFormThumbnailAllOf = {
  uploadUrl: Url
}

export interface FormThumbnail {
  createdAt: string
  id: Cuid
  tenantId: Cuid
  updatedAt: string
}

export type WithUploadUrlFormThumbnail = FormThumbnail & WithUploadUrlFormThumbnailAllOf

export type UpdateFormSlideContentInput =
  PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrSalesforceCampaignIdOrNextSlideIdOrFormThumbnailId

/**
 * @nullable
 */
export type PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrSalesforceCampaignIdOrNextSlideIdOrFormThumbnailIdNextUrl =
  Url | null

/**
 * @nullable
 */
export type PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrSalesforceCampaignIdOrNextSlideIdOrFormThumbnailIdNextSlideId =
  Cuid | null

/**
 * @nullable
 */
export type PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrSalesforceCampaignIdOrNextSlideIdOrFormThumbnailIdFormThumbnailId =
  Cuid | null

/**
 * @nullable
 */
export type FormSlideContentNextSlideId = Cuid | null

/**
 * @nullable
 */
export type FormSlideContentFormThumbnailId = Cuid | null

export type Url = string

/**
 * @nullable
 */
export type FormSlideContentNextUrl = Url | null

export type Email = string

export type FormDesign = (typeof FormDesign)[keyof typeof FormDesign]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormDesign = {
  DEFAULT: 'DEFAULT',
  WITH_THUMBNAIL: 'WITH_THUMBNAIL',
} as const

/**
 * Make all properties in T optional
 */
export interface PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrSalesforceCampaignIdOrNextSlideIdOrFormThumbnailId {
  buttonText?: string
  description?: string
  descriptionUnderThumbnail?: string
  design?: FormDesign
  /** @nullable */
  formThumbnailId?: PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrSalesforceCampaignIdOrNextSlideIdOrFormThumbnailIdFormThumbnailId
  /** @nullable */
  nextSlideId?: PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrSalesforceCampaignIdOrNextSlideIdOrFormThumbnailIdNextSlideId
  /** @nullable */
  nextUrl?: PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrSalesforceCampaignIdOrNextSlideIdOrFormThumbnailIdNextUrl
  notificationEmail?: Email
  notifyWithFormAnswers?: boolean
  privacyPolicyCheckboxText?: string
  privacyPolicyText?: string
  privacyPolicyUrl?: Url
  salesforceCampaignId?: string
  thanksMailBcc?: string
  thanksMailBodyConclusion?: string
  thanksMailBodyIntroduction?: string
  thanksMailDisplayName?: string
  thanksMailEnabled?: boolean
  thanksMailSubject?: string
}

export interface FormSlideContent {
  buttonText: string
  createdAt: string
  description: string
  descriptionUnderThumbnail: string
  design: FormDesign
  /** @nullable */
  formThumbnailId: FormSlideContentFormThumbnailId
  id: Cuid
  /** @nullable */
  nextSlideId: FormSlideContentNextSlideId
  /** @nullable */
  nextUrl: FormSlideContentNextUrl
  notificationEmail: Email
  notifyWithFormAnswers: boolean
  privacyPolicyCheckboxText: string
  privacyPolicyText: string
  privacyPolicyUrl: Url
  salesforceCampaignId: string
  slideId: Cuid
  thanksMailBcc: string
  thanksMailBodyConclusion: string
  thanksMailBodyIntroduction: string
  thanksMailDisplayName: string
  thanksMailEnabled: boolean
  thanksMailSubject: string
  updatedAt: string
}

/**
 * @nullable
 */
export type PartialOmitCreateFormInputInputFormSlideContentIdAndPickFormInputPositionSalesforceLeadProperty =
  SalesforceCreateLeadProperty | null

/**
 * Make all properties in T optional
 */
export interface PartialOmitCreateFormInputInputFormSlideContentIdAndPickFormInputPosition {
  /** @nullable */
  hubspotContactProperty?: PartialOmitCreateFormInputInputFormSlideContentIdAndPickFormInputPositionHubspotContactProperty
  name?: string
  placeholder?: string
  position?: number
  required?: boolean
  /** @nullable */
  salesforceLeadProperty?: PartialOmitCreateFormInputInputFormSlideContentIdAndPickFormInputPositionSalesforceLeadProperty
  type?: FormInputType
}

export type UpdateFormInputInput = PartialOmitCreateFormInputInputFormSlideContentIdAndPickFormInputPosition

export type HubspotCreateContactProperty =
  (typeof HubspotCreateContactProperty)[keyof typeof HubspotCreateContactProperty]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HubspotCreateContactProperty = {
  company: 'company',
  email: 'email',
  firstname: 'firstname',
  lastname: 'lastname',
  numemployees: 'numemployees',
  phone: 'phone',
  state: 'state',
  city: 'city',
  jobtitle: 'jobtitle',
} as const

/**
 * @nullable
 */
export type PartialOmitCreateFormInputInputFormSlideContentIdAndPickFormInputPositionHubspotContactProperty =
  HubspotCreateContactProperty | null

/**
 * @nullable
 */
export type PickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentIdHubspotContactProperty =
  HubspotCreateContactProperty | null

/**
 * @nullable
 */
export type FormInputHubspotContactProperty = HubspotCreateContactProperty | null

export type SalesforceCreateLeadProperty =
  (typeof SalesforceCreateLeadProperty)[keyof typeof SalesforceCreateLeadProperty]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesforceCreateLeadProperty = {
  Company: 'Company',
  Email: 'Email',
  FirstName: 'FirstName',
  LastName: 'LastName',
  NumberOfEmployees: 'NumberOfEmployees',
  Phone: 'Phone',
  State: 'State',
  City: 'City',
  Title: 'Title',
} as const

/**
 * @nullable
 */
export type PickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentIdSalesforceLeadProperty =
  SalesforceCreateLeadProperty | null

/**
 * @nullable
 */
export type FormInputSalesforceLeadProperty = SalesforceCreateLeadProperty | null

export type FormInputType = (typeof FormInputType)[keyof typeof FormInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormInputType = {
  TEXT: 'TEXT',
  EMAIL: 'EMAIL',
  SELECT: 'SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
} as const

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentId {
  formSlideContentId: Cuid
  /** @nullable */
  hubspotContactProperty: PickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentIdHubspotContactProperty
  name: string
  placeholder: string
  required: boolean
  /** @nullable */
  salesforceLeadProperty: PickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentIdSalesforceLeadProperty
  type: FormInputType
}

export type CreateFormInputInput =
  PickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentId

export interface FormInput {
  createdAt: string
  formSlideContentId: Cuid
  /** @nullable */
  hubspotContactProperty: FormInputHubspotContactProperty
  id: Cuid
  name: string
  placeholder: string
  position: number
  required: boolean
  /** @nullable */
  salesforceLeadProperty: FormInputSalesforceLeadProperty
  type: FormInputType
  updatedAt: string
}

/**
 * Make all properties in T optional
 */
export interface PartialPickFormInputOptionValueOrLabel {
  label?: string
  value?: string
}

export type UpdateFormInputOptionInput = PartialPickFormInputOptionValueOrLabel

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickFormInputOptionFormInputIdOrValueOrLabel {
  formInputId: Cuid
  label: string
  value: string
}

export type CreateFormInputOptionInput = PickFormInputOptionFormInputIdOrValueOrLabel

export interface FormInputOption {
  createdAt: string
  formInputId: Cuid
  id: Cuid
  label: string
  updatedAt: string
  value: string
}

export interface CreateEmbedScriptInput {
  floatConfig?: CreateEmbedScriptInputFloatConfig
}

/**
 * @nullable
 */
export type CreateEmbedScriptInputFloatConfigScenarioMobileThumbnailId = Cuid | null

export type MobileDisplayType = (typeof MobileDisplayType)[keyof typeof MobileDisplayType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MobileDisplayType = {
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
  SIDE_LEFT: 'SIDE_LEFT',
  SIDE_RIGHT: 'SIDE_RIGHT',
  CUSTOM_TOP_LEFT: 'CUSTOM_TOP_LEFT',
  CUSTOM_TOP_RIGHT: 'CUSTOM_TOP_RIGHT',
  CUSTOM_SIDE_LEFT: 'CUSTOM_SIDE_LEFT',
  CUSTOM_SIDE_RIGHT: 'CUSTOM_SIDE_RIGHT',
  CUSTOM_BOTTOM_LEFT: 'CUSTOM_BOTTOM_LEFT',
  CUSTOM_BOTTOM_RIGHT: 'CUSTOM_BOTTOM_RIGHT',
  NONE: 'NONE',
} as const

export type DesktopDisplayHorizontalOffsetType =
  (typeof DesktopDisplayHorizontalOffsetType)[keyof typeof DesktopDisplayHorizontalOffsetType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DesktopDisplayHorizontalOffsetType = {
  FROM_LEFT: 'FROM_LEFT',
  FROM_RIGHT: 'FROM_RIGHT',
} as const

export type DesktopDisplayVerticalOffsetType =
  (typeof DesktopDisplayVerticalOffsetType)[keyof typeof DesktopDisplayVerticalOffsetType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DesktopDisplayVerticalOffsetType = {
  FROM_TOP: 'FROM_TOP',
  FROM_BOTTOM: 'FROM_BOTTOM',
} as const

export type DesktopDisplayType = (typeof DesktopDisplayType)[keyof typeof DesktopDisplayType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DesktopDisplayType = {
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
  CUSTOM_OFFSET: 'CUSTOM_OFFSET',
} as const

export type CreateEmbedScriptInputFloatConfig = {
  desktopDisplayHorizontalOffset: number
  desktopDisplayHorizontalOffsetType: DesktopDisplayHorizontalOffsetType
  desktopDisplayType: DesktopDisplayType
  desktopDisplayVerticalOffset: number
  desktopDisplayVerticalOffsetType: DesktopDisplayVerticalOffsetType
  mobileDisplayType: MobileDisplayType
  scenarioId: Cuid
  /** @nullable */
  scenarioMobileThumbnailId?: CreateEmbedScriptInputFloatConfigScenarioMobileThumbnailId
}

export interface EmbedScript {
  createdAt: string
  id: Cuid
  updatedAt: string
}

export type BatchOperationStatus = (typeof BatchOperationStatus)[keyof typeof BatchOperationStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BatchOperationStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  ERROR: 'ERROR',
} as const

export interface BatchOperation {
  createdAt: string
  id: Cuid
  status: BatchOperationStatus
  tenantId: Cuid
  updatedAt: string
}

export interface UpdateApiKeyInput {
  isDisabled?: boolean
  name?: string
}

export interface CreateApiKeyInput {
  isDisabled?: boolean
  name: string
}

export type Cuid = string

export interface VideoSlideContent {
  createdAt: string
  id: Cuid
  /** @nullable */
  nextSlideId: VideoSlideContentNextSlideId
  /** @nullable */
  nextUrl: VideoSlideContentNextUrl
  slideId: Cuid
  updatedAt: string
  /** @nullable */
  videoId: VideoSlideContentVideoId
}

export interface ApiKey {
  createdAt: string
  id: Cuid
  isDisabled: boolean
  name: string
  tenantId: Cuid
  updatedAt: string
}

export const listApiKeys = () => {
  return loovPrivateEndpointClient<ApiKey[]>({ url: `/apiKeys`, method: 'GET' })
}

export const createApiKey = (createApiKeyInput: CreateApiKeyInput) => {
  return loovPrivateEndpointClient<CreateApiKey200>({
    url: `/apiKeys`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createApiKeyInput,
  })
}

export const updateApiKey = (apiKeyId: Cuid, updateApiKeyInput: UpdateApiKeyInput) => {
  return loovPrivateEndpointClient<ApiKey>({
    url: `/apiKeys/${apiKeyId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateApiKeyInput,
  })
}

export const deleteApiKey = (apiKeyId: Cuid) => {
  return loovPrivateEndpointClient<ApiKey>({ url: `/apiKeys/${apiKeyId}`, method: 'DELETE' })
}

export const getBatchOperation = (batchOperationId: string) => {
  return loovPrivateEndpointClient<BatchOperation>({ url: `/batch-operations/${batchOperationId}`, method: 'GET' })
}

export const createEmbedScript = (createEmbedScriptInput: CreateEmbedScriptInput) => {
  return loovPrivateEndpointClient<EmbedScript>({
    url: `/embed-scripts`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createEmbedScriptInput,
  })
}

/**
 * 視聴履歴をcsvとしてエクスポートする。

ヘッダーは
- <視聴日時>, <...[フォーム回答結果]>, <...[ユーザーアクション]>
となる

フォーム回答結果は、(publishedFormInputIdではなく)formInputId が同じものは1つの列に表示され、
ヘッダーには該当の formInput のうち最後に公開された時の入力項目名が表示される

ユーザーアクションは、
- 動画視聴のとき -> 【動画視聴】スライド名
- フォームスライド視聴のとき -> 【フォーム回答】スライド名
- 設問回答のとき -> 【設問回答】（回答時点での）設問名 / (回答時点での) 選択肢名
を表示する
 */
export const exportLeadSessions = (params: ExportLeadSessionsParams) => {
  return loovPrivateEndpointClient<string>({ url: `/export/lead-sessions`, method: 'GET', params })
}

/**
 * 設問回答割合のCSVエクスポート
 */
export const exportSelectionAnswersSummary = (params: ExportSelectionAnswersSummaryParams) => {
  return loovPrivateEndpointClient<string>({ url: `/export/selection-answers-summary`, method: 'GET', params })
}

export const listFormInputOptions = (params: ListFormInputOptionsParams) => {
  return loovPrivateEndpointClient<FormInputOption[]>({ url: `/form-input-options`, method: 'GET', params })
}

export const createFormInputOption = (
  pickFormInputOptionFormInputIdOrValueOrLabel: PickFormInputOptionFormInputIdOrValueOrLabel,
) => {
  return loovPrivateEndpointClient<FormInputOption>({
    url: `/form-input-options`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: pickFormInputOptionFormInputIdOrValueOrLabel,
  })
}

export const getFormInputOption = (formInputOptionId: Cuid) => {
  return loovPrivateEndpointClient<FormInputOption>({ url: `/form-input-options/${formInputOptionId}`, method: 'GET' })
}

export const updateFormInputOption = (
  formInputOptionId: Cuid,
  partialPickFormInputOptionValueOrLabel: PartialPickFormInputOptionValueOrLabel,
) => {
  return loovPrivateEndpointClient<FormInputOption>({
    url: `/form-input-options/${formInputOptionId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialPickFormInputOptionValueOrLabel,
  })
}

export const deleteFormInputOption = (formInputOptionId: Cuid) => {
  return loovPrivateEndpointClient<void>({ url: `/form-input-options/${formInputOptionId}`, method: 'DELETE' })
}

export const listFormInputs = (params: ListFormInputsParams) => {
  return loovPrivateEndpointClient<FormInput[]>({ url: `/form-inputs`, method: 'GET', params })
}

export const createFormInput = (
  pickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentId: PickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentId,
) => {
  return loovPrivateEndpointClient<FormInput>({
    url: `/form-inputs`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: pickFormInputOrTypeOrNameOrPlaceholderOrRequiredOrSalesforceLeadPropertyOrHubspotContactPropertyOrFormSlideContentId,
  })
}

export const getFormInput = (formInputId: Cuid) => {
  return loovPrivateEndpointClient<FormInput>({ url: `/form-inputs/${formInputId}`, method: 'GET' })
}

export const updateFormInput = (
  formInputId: Cuid,
  partialOmitCreateFormInputInputFormSlideContentIdAndPickFormInputPosition: PartialOmitCreateFormInputInputFormSlideContentIdAndPickFormInputPosition,
) => {
  return loovPrivateEndpointClient<FormInput>({
    url: `/form-inputs/${formInputId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialOmitCreateFormInputInputFormSlideContentIdAndPickFormInputPosition,
  })
}

export const deleteFormInput = (formInputId: Cuid) => {
  return loovPrivateEndpointClient<void>({ url: `/form-inputs/${formInputId}`, method: 'DELETE' })
}

export const getFormSlideContentBySlideId = (params: GetFormSlideContentBySlideIdParams) => {
  return loovPrivateEndpointClient<FormSlideContent>({ url: `/form-slide-contents/bySlideId`, method: 'GET', params })
}

export const updateFormSlideContent = (
  formSlideContentId: Cuid,
  partialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrSalesforceCampaignIdOrNextSlideIdOrFormThumbnailId: PartialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrSalesforceCampaignIdOrNextSlideIdOrFormThumbnailId,
) => {
  return loovPrivateEndpointClient<FormSlideContent>({
    url: `/form-slide-contents/${formSlideContentId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialPickFormSlideContentOrThanksMailBodyConclusionOrThanksMailBodyIntroductionOrThanksMailSubjectOrThanksMailBccOrThanksMailDisplayNameOrThanksMailEnabledOrButtonTextOrPrivacyPolicyUrlOrPrivacyPolicyTextOrPrivacyPolicyCheckboxTextOrNotificationEmailOrNotifyWithFormAnswersOrDescriptionOrDescriptionUnderThumbnailOrDesignOrNextUrlOrSalesforceCampaignIdOrNextSlideIdOrFormThumbnailId,
  })
}

export const createFormThumbnail = (createFormThumbnailInput: CreateFormThumbnailInput) => {
  return loovPrivateEndpointClient<WithUploadUrlFormThumbnail>({
    url: `/form-thumbnails`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createFormThumbnailInput,
  })
}

export const getFormThumbnail = (formThumbnailId: Cuid) => {
  return loovPrivateEndpointClient<WithUrlFormThumbnail>({ url: `/form-thumbnails/${formThumbnailId}`, method: 'GET' })
}

export const listLeadProfiles = (params?: ListLeadProfilesParams) => {
  return loovPrivateEndpointClient<LeadProfile[]>({ url: `/lead-profiles`, method: 'GET', params })
}

export const createLeadProfile = (createLeadProfileInput: CreateLeadProfileInput) => {
  return loovPrivateEndpointClient<LeadProfile>({
    url: `/lead-profiles`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createLeadProfileInput,
  })
}

export const batchCreateLeadProfiles = (createLeadProfileInput: CreateLeadProfileInput[]) => {
  return loovPrivateEndpointClient<BatchOperation>({
    url: `/lead-profiles/_batch`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createLeadProfileInput,
  })
}

export const listLeadSessions = (params: ListLeadSessionsParams) => {
  return loovPrivateEndpointClient<ListLeadSessionsOutput>({ url: `/lead-sessions`, method: 'GET', params })
}

export const getLeadSessionDetail = (leadSessionId: Cuid) => {
  return loovPrivateEndpointClient<LeadSessionDetail>({ url: `/lead-sessions/${leadSessionId}/detail`, method: 'GET' })
}

export const deleteLeadSession = (leadSessionId: Cuid) => {
  return loovPrivateEndpointClient<void>({ url: `/lead-sessions/${leadSessionId}`, method: 'DELETE' })
}

export const createScenarioLogo = (createScenarioLogoInput: CreateScenarioLogoInput) => {
  return loovPrivateEndpointClient<WithUploadUrlScenarioLogo>({
    url: `/scenario-logos`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createScenarioLogoInput,
  })
}

export const getScenarioLogo = (scenarioLogoId: Cuid) => {
  return loovPrivateEndpointClient<WithUrlScenarioLogo>({ url: `/scenario-logos/${scenarioLogoId}`, method: 'GET' })
}

export const createScenarioMobileThumbnail = (
  createScenarioMobileThumbnailInput: CreateScenarioMobileThumbnailInput,
) => {
  return loovPrivateEndpointClient<WithUploadUrlScenarioMobileThumbnail>({
    url: `/scenario-mobile-thumbnails`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createScenarioMobileThumbnailInput,
  })
}

export const getScenarioMobileThumbnail = (scenarioMobileThumbnailId: Cuid) => {
  return loovPrivateEndpointClient<WithUrlScenarioMobileThumbnail>({
    url: `/scenario-mobile-thumbnails/${scenarioMobileThumbnailId}`,
    method: 'GET',
  })
}

export const listScenarioNotificationConditions = (params: ListScenarioNotificationConditionsParams) => {
  return loovPrivateEndpointClient<ScenarioNotificationCondition[]>({
    url: `/scenario-notification-conditions`,
    method: 'GET',
    params,
  })
}

export const createScenarioNotificationCondition = (
  createScenarioNotificationConditionInput: CreateScenarioNotificationConditionInput,
) => {
  return loovPrivateEndpointClient<ScenarioNotificationCondition>({
    url: `/scenario-notification-conditions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createScenarioNotificationConditionInput,
  })
}

export const getScenarioNotificationCondition = (scenarioNotificationConditionId: Cuid) => {
  return loovPrivateEndpointClient<ScenarioNotificationCondition>({
    url: `/scenario-notification-conditions/${scenarioNotificationConditionId}`,
    method: 'GET',
  })
}

export const updateScenarioNotificationCondition = (
  scenarioNotificationConditionId: Cuid,
  updateScenarioNotificationConditionInput: UpdateScenarioNotificationConditionInput,
) => {
  return loovPrivateEndpointClient<ScenarioNotificationCondition>({
    url: `/scenario-notification-conditions/${scenarioNotificationConditionId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateScenarioNotificationConditionInput,
  })
}

export const deleteScenarioNotificationCondition = (scenarioNotificationConditionId: Cuid) => {
  return loovPrivateEndpointClient<void>({
    url: `/scenario-notification-conditions/${scenarioNotificationConditionId}`,
    method: 'DELETE',
  })
}

export const listScenarioNotifications = (params: ListScenarioNotificationsParams) => {
  return loovPrivateEndpointClient<
    ReadonlyScenarioIdStringname3AstringrecipientEmailsStringArraynotifyProfiledLeadOnlyBooleanAndDomainModelMetaData[]
  >({ url: `/scenario-notifications`, method: 'GET', params })
}

export const createScenarioNotification = (
  pickScenarioNotificationScenarioIdOrNameOrRecipientEmailsOrNotifyProfiledLeadOnly: PickScenarioNotificationScenarioIdOrNameOrRecipientEmailsOrNotifyProfiledLeadOnly,
) => {
  return loovPrivateEndpointClient<ReadonlyScenarioIdStringname3AstringrecipientEmailsStringArraynotifyProfiledLeadOnlyBooleanAndDomainModelMetaData>(
    {
      url: `/scenario-notifications`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: pickScenarioNotificationScenarioIdOrNameOrRecipientEmailsOrNotifyProfiledLeadOnly,
    },
  )
}

export const getScenarioNotification = (scenarioNotificationId: Cuid) => {
  return loovPrivateEndpointClient<ReadonlyScenarioIdStringname3AstringrecipientEmailsStringArraynotifyProfiledLeadOnlyBooleanAndDomainModelMetaData>(
    { url: `/scenario-notifications/${scenarioNotificationId}`, method: 'GET' },
  )
}

export const updateScenarioNotification = (
  scenarioNotificationId: Cuid,
  partialPickScenarioNotificationNameOrRecipientEmailsOrNotifyProfiledLeadOnly: PartialPickScenarioNotificationNameOrRecipientEmailsOrNotifyProfiledLeadOnly,
) => {
  return loovPrivateEndpointClient<ReadonlyScenarioIdStringname3AstringrecipientEmailsStringArraynotifyProfiledLeadOnlyBooleanAndDomainModelMetaData>(
    {
      url: `/scenario-notifications/${scenarioNotificationId}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: partialPickScenarioNotificationNameOrRecipientEmailsOrNotifyProfiledLeadOnly,
    },
  )
}

export const deleteScenarioNotification = (scenarioNotificationId: Cuid) => {
  return loovPrivateEndpointClient<void>({ url: `/scenario-notifications/${scenarioNotificationId}`, method: 'DELETE' })
}

export const listScenarios = () => {
  return loovPrivateEndpointClient<Scenario[]>({ url: `/scenarios`, method: 'GET' })
}

export const createScenario = (createScenarioInput: CreateScenarioInput) => {
  return loovPrivateEndpointClient<Scenario>({
    url: `/scenarios`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createScenarioInput,
  })
}

export const getScenario = (scenarioId: Cuid) => {
  return loovPrivateEndpointClient<Scenario>({ url: `/scenarios/${scenarioId}`, method: 'GET' })
}

export const updateScenario = (
  scenarioId: Cuid,
  partialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrShowSelectionAnswerHistoryOrIsPublicOrIsAutoPlayEnabledOrScenarioPasswordOrDisableViewerTracking: PartialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrShowSelectionAnswerHistoryOrIsPublicOrIsAutoPlayEnabledOrScenarioPasswordOrDisableViewerTracking,
) => {
  return loovPrivateEndpointClient<Scenario>({
    url: `/scenarios/${scenarioId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialPickScenarioOrNameOrCtaTextOrCtaFontSizeOrCtaUrlOrCtaColorOrModalDescriptionOrIntroductionCompanyNameOrIntroductionDescriptionOrIntroductionTitleOrGoogleAnalyticsMeasurementIdOrScenarioLogoIdOrScenarioThumbnailIdOrScenarioMobileThumbnailIdOrIsGoBackButtonEnabledOrShowSelectionAnswerHistoryOrIsPublicOrIsAutoPlayEnabledOrScenarioPasswordOrDisableViewerTracking,
  })
}

export const deleteScenario = (scenarioId: Cuid) => {
  return loovPrivateEndpointClient<void>({ url: `/scenarios/${scenarioId}`, method: 'DELETE' })
}

export const getScenarioDetail = (scenarioId: Cuid) => {
  return loovPrivateEndpointClient<ScenarioDetail>({ url: `/scenarios/${scenarioId}/detail`, method: 'GET' })
}

export const getScenarioStatsSummary = (scenarioId: Cuid, params: GetScenarioStatsSummaryParams) => {
  return loovPrivateEndpointClient<ScenarioStatsSummary>({
    url: `/scenarios/${scenarioId}/stats/summary`,
    method: 'GET',
    params,
  })
}

export const getDailyScenarioStats = (scenarioId: Cuid, params: GetDailyScenarioStatsParams) => {
  return loovPrivateEndpointClient<DailyScenarioStats[]>({
    url: `/scenarios/${scenarioId}/stats/daily`,
    method: 'GET',
    params,
  })
}

export const publishScenario = (scenarioId: Cuid) => {
  return loovPrivateEndpointClient<void>({ url: `/scenarios/${scenarioId}/publish`, method: 'POST' })
}

export const generateScenario = (generateScenarioInput: GenerateScenarioInput) => {
  return loovPrivateEndpointClient<Scenario>({
    url: `/scenarios/generate`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: generateScenarioInput,
  })
}

export const createScenarioThumbnail = (createScenarioThumbnailInput: CreateScenarioThumbnailInput) => {
  return loovPrivateEndpointClient<WithUploadUrlScenarioThumbnail>({
    url: `/scenario-thumbnails`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createScenarioThumbnailInput,
  })
}

export const getScenarioThumbnail = (scenarioThumbnailId: Cuid) => {
  return loovPrivateEndpointClient<WithUrlScenarioThumbnail>({
    url: `/scenario-thumbnails/${scenarioThumbnailId}`,
    method: 'GET',
  })
}

export const listSelectionOptions = (params: ListSelectionOptionsParams) => {
  return loovPrivateEndpointClient<SelectionOption[]>({ url: `/selection-options`, method: 'GET', params })
}

export const createSelectionOption = (
  pickSelectionOptionSelectionSlideContentId: PickSelectionOptionSelectionSlideContentId,
) => {
  return loovPrivateEndpointClient<SelectionOption>({
    url: `/selection-options`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: pickSelectionOptionSelectionSlideContentId,
  })
}

export const getSelectionOption = (selectionOptionId: Cuid) => {
  return loovPrivateEndpointClient<SelectionOption>({ url: `/selection-options/${selectionOptionId}`, method: 'GET' })
}

export const updateSelectionOption = (
  selectionOptionId: Cuid,
  partialPickSelectionOptionValueOrNextSlideIdOrNextUrlOrPosition: PartialPickSelectionOptionValueOrNextSlideIdOrNextUrlOrPosition,
) => {
  return loovPrivateEndpointClient<SelectionOption>({
    url: `/selection-options/${selectionOptionId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialPickSelectionOptionValueOrNextSlideIdOrNextUrlOrPosition,
  })
}

export const deleteSelectionOption = (selectionOptionId: Cuid) => {
  return loovPrivateEndpointClient<void>({ url: `/selection-options/${selectionOptionId}`, method: 'DELETE' })
}

export const getSelectionSlideContentBySlideId = (params: GetSelectionSlideContentBySlideIdParams) => {
  return loovPrivateEndpointClient<SelectionSlideContent>({
    url: `/selection-slide-contents/bySlideId`,
    method: 'GET',
    params,
  })
}

export const updateSelectionSlideContent = (
  selectionSlideContentId: Cuid,
  partialPickSelectionSlideContentTextOrDirection: PartialPickSelectionSlideContentTextOrDirection,
) => {
  return loovPrivateEndpointClient<SelectionSlideContent>({
    url: `/selection-slide-contents/${selectionSlideContentId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialPickSelectionSlideContentTextOrDirection,
  })
}

/**
 * @summary スライド一覧を取得
 */
export const listSlides = (params: ListSlidesParams) => {
  return loovPrivateEndpointClient<Slide[]>({ url: `/slides`, method: 'GET', params })
}

/**
 * @summary スライドの作成
 */
export const createSlide = (pickSlideScenarioIdOrNameOrTypeOrPosition: PickSlideScenarioIdOrNameOrTypeOrPosition) => {
  return loovPrivateEndpointClient<Slide>({
    url: `/slides`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: pickSlideScenarioIdOrNameOrTypeOrPosition,
  })
}

export const getSlide = (slideId: Cuid) => {
  return loovPrivateEndpointClient<Slide>({ url: `/slides/${slideId}`, method: 'GET' })
}

export const updateSlide = (slideId: Cuid, updateSlideInput: UpdateSlideInput) => {
  return loovPrivateEndpointClient<Slide>({
    url: `/slides/${slideId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateSlideInput,
  })
}

export const deleteSlide = (slideId: Cuid) => {
  return loovPrivateEndpointClient<void>({ url: `/slides/${slideId}`, method: 'DELETE' })
}

export const listTenants = () => {
  return loovPrivateEndpointClient<Tenant[]>({ url: `/tenants`, method: 'GET' })
}

export const getTenant = (tenantId: string) => {
  return loovPrivateEndpointClient<Tenant>({ url: `/tenants/${tenantId}`, method: 'GET' })
}

export const updateTenant = (
  tenantId: string,
  partialPickTenantOrNameOrIsSalesforceIntegrationEnabledOrSalesforceMyDomainNameOrSalesforceClientIdOrSalesforceClientSecretOrSalesforceNotificationEmailOrIsHubspotIntegrationEnabledOrHubspotAccessTokenOrHubspotNotificationEmailOrSalesforceScenarioNameFieldNameOrSalesforceScenarioLeadSessionUrlFieldNameOrSalesforceScenarioLeadSessionIdFieldName: PartialPickTenantOrNameOrIsSalesforceIntegrationEnabledOrSalesforceMyDomainNameOrSalesforceClientIdOrSalesforceClientSecretOrSalesforceNotificationEmailOrIsHubspotIntegrationEnabledOrHubspotAccessTokenOrHubspotNotificationEmailOrSalesforceScenarioNameFieldNameOrSalesforceScenarioLeadSessionUrlFieldNameOrSalesforceScenarioLeadSessionIdFieldName,
) => {
  return loovPrivateEndpointClient<Tenant>({
    url: `/tenants/${tenantId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialPickTenantOrNameOrIsSalesforceIntegrationEnabledOrSalesforceMyDomainNameOrSalesforceClientIdOrSalesforceClientSecretOrSalesforceNotificationEmailOrIsHubspotIntegrationEnabledOrHubspotAccessTokenOrHubspotNotificationEmailOrSalesforceScenarioNameFieldNameOrSalesforceScenarioLeadSessionUrlFieldNameOrSalesforceScenarioLeadSessionIdFieldName,
  })
}

export const getFeatures = (tenantId: string) => {
  return loovPrivateEndpointClient<Feature[]>({ url: `/tenants/${tenantId}/features`, method: 'GET' })
}

export const synthesizeSpeech = (synthesizeSpeechInput: SynthesizeSpeechInput) => {
  return loovPrivateEndpointClient<string>({
    url: `/text-to-speech/synthesizeSpeech`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: synthesizeSpeechInput,
  })
}

export const getMe = () => {
  return loovPrivateEndpointClient<Me>({ url: `/user`, method: 'GET' })
}

export const updateMe = (partialPickMeFirstNameOrLastNameOrEmail: PartialPickMeFirstNameOrLastNameOrEmail) => {
  return loovPrivateEndpointClient<Me>({
    url: `/user`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialPickMeFirstNameOrLastNameOrEmail,
  })
}

export const listStarredScenarios = () => {
  return loovPrivateEndpointClient<Scenario[]>({ url: `/user/marking-scenarios`, method: 'GET' })
}

export const starScenario = (scenarioId: Cuid) => {
  return loovPrivateEndpointClient<void>({ url: `/user/marking-scenarios/${scenarioId}`, method: 'PUT' })
}

export const unstarScenario = (scenarioId: Cuid) => {
  return loovPrivateEndpointClient<void>({ url: `/user/marking-scenarios/${scenarioId}`, method: 'DELETE' })
}

/**
 * @summary ユーザイベントの送信
 */
export const createUserEvent = (userEventData: UserEventData) => {
  return loovPrivateEndpointClient<void>({
    url: `/user-events`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: userEventData,
  })
}

export const createUser = (createUserInput: CreateUserInput) => {
  return loovPrivateEndpointClient<SimpleUser>({
    url: `/users`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createUserInput,
  })
}

export const listUsers = () => {
  return loovPrivateEndpointClient<User[]>({ url: `/users`, method: 'GET' })
}

export const updateUser = (userId: string, updateUserInput: UpdateUserInput) => {
  return loovPrivateEndpointClient<SimpleUser>({
    url: `/users/${userId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateUserInput,
  })
}

export const deleteUser = (userId: string) => {
  return loovPrivateEndpointClient<void>({ url: `/users/${userId}`, method: 'DELETE' })
}

export const createVideoAvator = () => {
  return loovPrivateEndpointClient<WithUploadUrlVideoAvator>({ url: `/video-avators`, method: 'POST' })
}

export const getVideoAvator = (videoAvatorId: Cuid) => {
  return loovPrivateEndpointClient<WithUrlVideoAvator>({ url: `/video-avators/${videoAvatorId}`, method: 'GET' })
}

export const listVideoScenes = (params: ListVideoScenesParams) => {
  return loovPrivateEndpointClient<ReadonlyVideoScenePropsAndDomainModelMetaData[]>({
    url: `/video-scenes`,
    method: 'GET',
    params,
  })
}

export const createVideoScene = (createVideoSceneInput: CreateVideoSceneInput) => {
  return loovPrivateEndpointClient<WithUploadUrlVideoScene>({
    url: `/video-scenes`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createVideoSceneInput,
  })
}

export const getVideoScene = (videoSceneId: string) => {
  return loovPrivateEndpointClient<WithUrlVideoScene>({ url: `/video-scenes/${videoSceneId}`, method: 'GET' })
}

export const updateVideoScene = (videoSceneId: string, updateVideoSceneInput: UpdateVideoSceneInput) => {
  return loovPrivateEndpointClient<ReadonlyVideoScenePropsAndDomainModelMetaData>({
    url: `/video-scenes/${videoSceneId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateVideoSceneInput,
  })
}

export const deleteVideoScene = (videoSceneId: string) => {
  return loovPrivateEndpointClient<void>({ url: `/video-scenes/${videoSceneId}`, method: 'DELETE' })
}

export const listVideos = (params?: ListVideosParams) => {
  return loovPrivateEndpointClient<Video[]>({ url: `/videos`, method: 'GET', params })
}

export const createVideo = (createVideoInput: CreateVideoInput) => {
  return loovPrivateEndpointClient<CreateVideoOutput>({
    url: `/videos`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createVideoInput,
  })
}

export const getVideo = (videoId: Cuid, params?: GetVideoParams) => {
  return loovPrivateEndpointClient<VideoWithUrl>({ url: `/videos/${videoId}`, method: 'GET', params })
}

export const updateVideo = (videoId: Cuid, updateVideoInput: UpdateVideoInput) => {
  return loovPrivateEndpointClient<VideoWithUrl>({
    url: `/videos/${videoId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateVideoInput,
  })
}

export const deleteVideo = (videoId: Cuid) => {
  return loovPrivateEndpointClient<void>({ url: `/videos/${videoId}`, method: 'DELETE' })
}

export const getVideoCaption = (videoId: Cuid) => {
  return loovPrivateEndpointClient<VideoCaptionSegment[]>({ url: `/videos/${videoId}/caption`, method: 'GET' })
}

export const setVideoCaption = (videoId: Cuid, setVideoCaptionInput: SetVideoCaptionInput) => {
  return loovPrivateEndpointClient<void>({
    url: `/videos/${videoId}/caption`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: setVideoCaptionInput,
  })
}

export const listVideoSlideContent = (params?: ListVideoSlideContentParams) => {
  return loovPrivateEndpointClient<VideoSlideContent[]>({ url: `/video-slide-contents`, method: 'GET', params })
}

export const getVideoSlideContentBySlideId = (params: GetVideoSlideContentBySlideIdParams) => {
  return loovPrivateEndpointClient<VideoSlideContent>({ url: `/video-slide-contents/bySlideId`, method: 'GET', params })
}

export const updateVideoSlideContent = (
  videoSlideContentId: Cuid,
  partialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrl: PartialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrl,
) => {
  return loovPrivateEndpointClient<VideoSlideContent>({
    url: `/video-slide-contents/${videoSlideContentId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partialPickVideoSlideContentVideoIdOrNextSlideIdOrNextUrl,
  })
}

export type ListApiKeysResult = NonNullable<Awaited<ReturnType<typeof listApiKeys>>>
export type CreateApiKeyResult = NonNullable<Awaited<ReturnType<typeof createApiKey>>>
export type UpdateApiKeyResult = NonNullable<Awaited<ReturnType<typeof updateApiKey>>>
export type DeleteApiKeyResult = NonNullable<Awaited<ReturnType<typeof deleteApiKey>>>
export type GetBatchOperationResult = NonNullable<Awaited<ReturnType<typeof getBatchOperation>>>
export type CreateEmbedScriptResult = NonNullable<Awaited<ReturnType<typeof createEmbedScript>>>
export type ExportLeadSessionsResult = NonNullable<Awaited<ReturnType<typeof exportLeadSessions>>>
export type ExportSelectionAnswersSummaryResult = NonNullable<Awaited<ReturnType<typeof exportSelectionAnswersSummary>>>
export type ListFormInputOptionsResult = NonNullable<Awaited<ReturnType<typeof listFormInputOptions>>>
export type CreateFormInputOptionResult = NonNullable<Awaited<ReturnType<typeof createFormInputOption>>>
export type GetFormInputOptionResult = NonNullable<Awaited<ReturnType<typeof getFormInputOption>>>
export type UpdateFormInputOptionResult = NonNullable<Awaited<ReturnType<typeof updateFormInputOption>>>
export type DeleteFormInputOptionResult = NonNullable<Awaited<ReturnType<typeof deleteFormInputOption>>>
export type ListFormInputsResult = NonNullable<Awaited<ReturnType<typeof listFormInputs>>>
export type CreateFormInputResult = NonNullable<Awaited<ReturnType<typeof createFormInput>>>
export type GetFormInputResult = NonNullable<Awaited<ReturnType<typeof getFormInput>>>
export type UpdateFormInputResult = NonNullable<Awaited<ReturnType<typeof updateFormInput>>>
export type DeleteFormInputResult = NonNullable<Awaited<ReturnType<typeof deleteFormInput>>>
export type GetFormSlideContentBySlideIdResult = NonNullable<Awaited<ReturnType<typeof getFormSlideContentBySlideId>>>
export type UpdateFormSlideContentResult = NonNullable<Awaited<ReturnType<typeof updateFormSlideContent>>>
export type CreateFormThumbnailResult = NonNullable<Awaited<ReturnType<typeof createFormThumbnail>>>
export type GetFormThumbnailResult = NonNullable<Awaited<ReturnType<typeof getFormThumbnail>>>
export type ListLeadProfilesResult = NonNullable<Awaited<ReturnType<typeof listLeadProfiles>>>
export type CreateLeadProfileResult = NonNullable<Awaited<ReturnType<typeof createLeadProfile>>>
export type BatchCreateLeadProfilesResult = NonNullable<Awaited<ReturnType<typeof batchCreateLeadProfiles>>>
export type ListLeadSessionsResult = NonNullable<Awaited<ReturnType<typeof listLeadSessions>>>
export type GetLeadSessionDetailResult = NonNullable<Awaited<ReturnType<typeof getLeadSessionDetail>>>
export type DeleteLeadSessionResult = NonNullable<Awaited<ReturnType<typeof deleteLeadSession>>>
export type CreateScenarioLogoResult = NonNullable<Awaited<ReturnType<typeof createScenarioLogo>>>
export type GetScenarioLogoResult = NonNullable<Awaited<ReturnType<typeof getScenarioLogo>>>
export type CreateScenarioMobileThumbnailResult = NonNullable<Awaited<ReturnType<typeof createScenarioMobileThumbnail>>>
export type GetScenarioMobileThumbnailResult = NonNullable<Awaited<ReturnType<typeof getScenarioMobileThumbnail>>>
export type ListScenarioNotificationConditionsResult = NonNullable<
  Awaited<ReturnType<typeof listScenarioNotificationConditions>>
>
export type CreateScenarioNotificationConditionResult = NonNullable<
  Awaited<ReturnType<typeof createScenarioNotificationCondition>>
>
export type GetScenarioNotificationConditionResult = NonNullable<
  Awaited<ReturnType<typeof getScenarioNotificationCondition>>
>
export type UpdateScenarioNotificationConditionResult = NonNullable<
  Awaited<ReturnType<typeof updateScenarioNotificationCondition>>
>
export type DeleteScenarioNotificationConditionResult = NonNullable<
  Awaited<ReturnType<typeof deleteScenarioNotificationCondition>>
>
export type ListScenarioNotificationsResult = NonNullable<Awaited<ReturnType<typeof listScenarioNotifications>>>
export type CreateScenarioNotificationResult = NonNullable<Awaited<ReturnType<typeof createScenarioNotification>>>
export type GetScenarioNotificationResult = NonNullable<Awaited<ReturnType<typeof getScenarioNotification>>>
export type UpdateScenarioNotificationResult = NonNullable<Awaited<ReturnType<typeof updateScenarioNotification>>>
export type DeleteScenarioNotificationResult = NonNullable<Awaited<ReturnType<typeof deleteScenarioNotification>>>
export type ListScenariosResult = NonNullable<Awaited<ReturnType<typeof listScenarios>>>
export type CreateScenarioResult = NonNullable<Awaited<ReturnType<typeof createScenario>>>
export type GetScenarioResult = NonNullable<Awaited<ReturnType<typeof getScenario>>>
export type UpdateScenarioResult = NonNullable<Awaited<ReturnType<typeof updateScenario>>>
export type DeleteScenarioResult = NonNullable<Awaited<ReturnType<typeof deleteScenario>>>
export type GetScenarioDetailResult = NonNullable<Awaited<ReturnType<typeof getScenarioDetail>>>
export type GetScenarioStatsSummaryResult = NonNullable<Awaited<ReturnType<typeof getScenarioStatsSummary>>>
export type GetDailyScenarioStatsResult = NonNullable<Awaited<ReturnType<typeof getDailyScenarioStats>>>
export type PublishScenarioResult = NonNullable<Awaited<ReturnType<typeof publishScenario>>>
export type GenerateScenarioResult = NonNullable<Awaited<ReturnType<typeof generateScenario>>>
export type CreateScenarioThumbnailResult = NonNullable<Awaited<ReturnType<typeof createScenarioThumbnail>>>
export type GetScenarioThumbnailResult = NonNullable<Awaited<ReturnType<typeof getScenarioThumbnail>>>
export type ListSelectionOptionsResult = NonNullable<Awaited<ReturnType<typeof listSelectionOptions>>>
export type CreateSelectionOptionResult = NonNullable<Awaited<ReturnType<typeof createSelectionOption>>>
export type GetSelectionOptionResult = NonNullable<Awaited<ReturnType<typeof getSelectionOption>>>
export type UpdateSelectionOptionResult = NonNullable<Awaited<ReturnType<typeof updateSelectionOption>>>
export type DeleteSelectionOptionResult = NonNullable<Awaited<ReturnType<typeof deleteSelectionOption>>>
export type GetSelectionSlideContentBySlideIdResult = NonNullable<
  Awaited<ReturnType<typeof getSelectionSlideContentBySlideId>>
>
export type UpdateSelectionSlideContentResult = NonNullable<Awaited<ReturnType<typeof updateSelectionSlideContent>>>
export type ListSlidesResult = NonNullable<Awaited<ReturnType<typeof listSlides>>>
export type CreateSlideResult = NonNullable<Awaited<ReturnType<typeof createSlide>>>
export type GetSlideResult = NonNullable<Awaited<ReturnType<typeof getSlide>>>
export type UpdateSlideResult = NonNullable<Awaited<ReturnType<typeof updateSlide>>>
export type DeleteSlideResult = NonNullable<Awaited<ReturnType<typeof deleteSlide>>>
export type ListTenantsResult = NonNullable<Awaited<ReturnType<typeof listTenants>>>
export type GetTenantResult = NonNullable<Awaited<ReturnType<typeof getTenant>>>
export type UpdateTenantResult = NonNullable<Awaited<ReturnType<typeof updateTenant>>>
export type GetFeaturesResult = NonNullable<Awaited<ReturnType<typeof getFeatures>>>
export type SynthesizeSpeechResult = NonNullable<Awaited<ReturnType<typeof synthesizeSpeech>>>
export type GetMeResult = NonNullable<Awaited<ReturnType<typeof getMe>>>
export type UpdateMeResult = NonNullable<Awaited<ReturnType<typeof updateMe>>>
export type ListStarredScenariosResult = NonNullable<Awaited<ReturnType<typeof listStarredScenarios>>>
export type StarScenarioResult = NonNullable<Awaited<ReturnType<typeof starScenario>>>
export type UnstarScenarioResult = NonNullable<Awaited<ReturnType<typeof unstarScenario>>>
export type CreateUserEventResult = NonNullable<Awaited<ReturnType<typeof createUserEvent>>>
export type CreateUserResult = NonNullable<Awaited<ReturnType<typeof createUser>>>
export type ListUsersResult = NonNullable<Awaited<ReturnType<typeof listUsers>>>
export type UpdateUserResult = NonNullable<Awaited<ReturnType<typeof updateUser>>>
export type DeleteUserResult = NonNullable<Awaited<ReturnType<typeof deleteUser>>>
export type CreateVideoAvatorResult = NonNullable<Awaited<ReturnType<typeof createVideoAvator>>>
export type GetVideoAvatorResult = NonNullable<Awaited<ReturnType<typeof getVideoAvator>>>
export type ListVideoScenesResult = NonNullable<Awaited<ReturnType<typeof listVideoScenes>>>
export type CreateVideoSceneResult = NonNullable<Awaited<ReturnType<typeof createVideoScene>>>
export type GetVideoSceneResult = NonNullable<Awaited<ReturnType<typeof getVideoScene>>>
export type UpdateVideoSceneResult = NonNullable<Awaited<ReturnType<typeof updateVideoScene>>>
export type DeleteVideoSceneResult = NonNullable<Awaited<ReturnType<typeof deleteVideoScene>>>
export type ListVideosResult = NonNullable<Awaited<ReturnType<typeof listVideos>>>
export type CreateVideoResult = NonNullable<Awaited<ReturnType<typeof createVideo>>>
export type GetVideoResult = NonNullable<Awaited<ReturnType<typeof getVideo>>>
export type UpdateVideoResult = NonNullable<Awaited<ReturnType<typeof updateVideo>>>
export type DeleteVideoResult = NonNullable<Awaited<ReturnType<typeof deleteVideo>>>
export type GetVideoCaptionResult = NonNullable<Awaited<ReturnType<typeof getVideoCaption>>>
export type SetVideoCaptionResult = NonNullable<Awaited<ReturnType<typeof setVideoCaption>>>
export type ListVideoSlideContentResult = NonNullable<Awaited<ReturnType<typeof listVideoSlideContent>>>
export type GetVideoSlideContentBySlideIdResult = NonNullable<Awaited<ReturnType<typeof getVideoSlideContentBySlideId>>>
export type UpdateVideoSlideContentResult = NonNullable<Awaited<ReturnType<typeof updateVideoSlideContent>>>
